@import "./src/scss/index";
.toastBody {
    background-color: $themeMono1;
    border-radius: $borderRadius;
    display: flex;
    font-family: $fontFamily;

    justify-content: stretch;
    align-items: stretch;
    width: 100%;

    .toastContent {
        flex-grow: 1;
        padding: 1.5rem 1.25rem 1.5rem 1.5rem;
    }

    h1 {
        color: $themeMono12;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 400;

        & + p {
            margin-top: 0.4375rem;
        }
    }

    p {
        color: $themeMono11;
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 300;
    }

    .iconOnly {
        padding: 0 1.25rem;
        margin-left: 0.625rem;
        color: $themeMono10;

        svg {
            height: 1.125rem;
            width: 1.125rem;
        }

        &:hover {
            color: $themeColor3;
        }
    }

    .iconWrap {
        color: $themeMono1;
        display: flex;
        align-items: center;
        padding: 0.6875rem;
        width: 3.75rem;

        & > svg {
            width: 2.375rem;
        }
    }

    &.success {
        .iconWrap {
            background-color: $positive;
            color: $positive;
        }
    }

    &.warning {
        .iconWrap {
            background-color: $neutral;
            color: $neutral;
        }
    }

    &.error {
        background-color: transparent;
        margin: 0;
        padding: 0;

        .iconWrap {
            background-color: $negative;
            color: $negative;
        }
    }

    &.info {
        .iconWrap {
            background-color: $info;
            color: $info;
        }
    }
}

.enterAnimation {
    animation-name: fadeInSlide;
}

.exitAnimation {
    animation-name: fadeOutScale;
}

@keyframes fadeInSlide {
    0% {
        opacity: 0;
        transform: translate(100px, 0px);
    }
    100% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

@-webkit-keyframes fadeInSlide {
    0% {
        opacity: 0;
        -webkit-transform: translate(100px, 0px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes fadeOutScale {
    0% {
        opacity: 1;
        transform: translate(0px, 0px) scaleX(1) scaleY(1);
    }
    100% {
        opacity: 0;
        transform: translate(0px, -10px) scaleX(0.9) scaleY(0.9);
    }
}

@-webkit-keyframes fadeOutScale {
    0% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px) scaleX(1) scaleY(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(0px, -10px) scaleX(0.9) scaleY(0.9);
    }
}

/* Overrides */
.Toastify__toast-container {
    max-width: 31.25rem !important;
    width: 100% !important;
}
.Toastify__close-button {
    display: none !important;
}

.Toastify__toast {
    border-radius: 0.5rem !important;
    padding: 0 !important;
    will-change: opacity, transform;

    &-body {
        display: flex;
    }
}

.Toastify__toast-container--top-right {
    top: 0.5rem !important;
    right: 0.5rem !important;
}
