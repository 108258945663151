@import "./src/scss/index";
.report-filter-content-scroll {
    .report-filter-count-container {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        margin-bottom: 1rem;

        .inputWrapper label + input {
            margin-top: 0;
        }

        .report-filter-count-error {
            font-size: 14px;
            color: $themeColor21;
        }

        .report-filter-count {
            margin-bottom: 0.3rem;
            flex-direction: row;
            align-items: center;
            .label {
                margin-right: 1rem;
                color: $themeMono11;
            }
        }
    }

    .radio-button-selector {
        height: auto;
        padding: 0;
        .MuiFormGroup-row {
            flex-direction: column;
        }
    }
}
