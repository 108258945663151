@import "./src/scss/index";
.reports-page {
  position: relative;

  .type-n-date {
    padding: 1.5rem 2rem;
    display: flex;

    .report-type{
      width: 20rem;
    }

    .inputWrapper{
      margin: 0;
    }

    .date-range-picker{
      margin-left: 3rem;
    }

    .checkbox-n-date-range{
      display: flex;
      align-items: flex-end;
      margin-left: 1.75rem;

      .checkbox{
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
      }

      .label{
        margin:0 0 0.4rem 0.5rem;
        color: $themeMono12;
      }

      .date-range-picker{
        margin-left: 1.25rem;
      }
    }
  }

  .report-filters{
    display: flex;
    flex-direction: column;
    background-color: $themeMono16;
    height: 100%;
    padding: 2rem;


    > .inputWrapper, .site-groups-n-sites-n-areas .inputWrapper, .site-groups-n-sites .inputWrapper{
      width: 15rem;
      margin: 0 2rem 2rem 0;
    }

    .filter-label{
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    .site-groups-n-sites-n-areas, .site-groups-n-sites{
      display: flex;
    }

    .company-search{
      margin-bottom: 1.5rem;
    }
  }

  .filter-placeholder{
    display: flex;
    background-color: $themeMono16;
    height: 100%;
    justify-content: center;
    padding-top: 10rem;
    font-size: 1.25rem;
    color: black;
    font-weight: 300;
  }

  .actionButtonsPanel{
    background-color: $themeMono2;
  }
}
