@import "./src/scss/index";
.edit-repeat-buttons {
    height: 100%;
    .alignRight {
        flex-direction: column;

        .button {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}
