@import "./src/scss/index";
.result-count-and-new-visitor-btn {
  display: flex;
  margin-top: 1.5625rem;

  .result-count {
    display: flex;
    align-items: flex-start;
    font-size: 0.75rem;

    .count {
      font-weight: $fontWeightBold;
      color: $themeMono17;
    }

    .label {
      color: $themeMono10;
    }
  }

  .new-visitor-btn{
    display: flex;
    align-items: center;
    align-self: end;
    margin-left: auto;
    cursor: pointer;

    svg{
      height: 0.5625rem;
      margin-right: 0.3125rem;
    }
    color: $themeColor12;
  }
}

.search-result {
  border-top: 1px solid $themeMono6;
  border-bottom: 1px solid $themeMono6;
  overflow-y: auto;

  .placeholder {
    margin: 2.75rem 4rem;
    font-size: 1rem;
    color: $themeMono11;
    font-weight: $fontWeightLight;
    width: 14.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
