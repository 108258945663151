@import "./src/scss/index";
$arrowSize: 0.3125rem;

.tooltipWrapper {
    position: absolute;
    display: none;

    &.top,
    &.bottom {
        justify-content: center;
        transform: translate(-50%, 0);
    }

    &.left,
    &.right {
        transform: translate(0, -50%);
    }

    &.left {
        justify-content: flex-end;
    }

    .tooltip {
        position: relative;
        font-size: 0.75rem;
        line-height: 1;
        color: $themeMono12;
        padding: 0.25rem 0.9375rem;
        background: $themeMono1;
        border-radius: 0.3125rem;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
        width: max-content;
        overflow-wrap: break-word;

        .tooltipText {
            &.top::after,
            &.right::after,
            &.left::after,
            &.bottom::after {
                content: '';
                position: absolute;
                border-width: $arrowSize;
                border-style: solid;
            }

            &.top::after,
            &.bottom::after {
                left: 50%;
                margin-left: -$arrowSize;
            }

            &.left::after,
            &.right::after {
                top: 50%;
                margin-top: -$arrowSize;
            }

            &.top::after {
                top: 100%;
                border-color: $themeMono1 transparent transparent transparent;
            }

            &.right::after {
                right: 100%;
                border-color: transparent $themeMono1 transparent transparent;
            }

            &.left::after {
                left: 100%;
                border-color: transparent transparent transparent $themeMono1;
            }

            &.bottom::after {
                bottom: 100%;
                border-color: transparent transparent $themeMono1 transparent;
            }
        }

        &.available {
            border: 1px solid $themeColor31;
        }

        &.booked {
            border: 1px solid $themeColor6;
        }

        &.mybooking {
            border: 1px solid $themeColor16;
        }

        &.unavailable {
            border: 1px solid $themeColor23;
        }

        &.unavailable_COVID {
            border: 1px solid $themeColor19;
        }

        &.not-working-time {
            border: 1px solid $themeColor23;
        }

        &.restricted {
            border: 1px solid $themeColor23;
        }
    }
}
