@import "./src/scss/index";
.floorplan-search {
    &-container {
        padding: 0 2rem;
        font-family: $fontFamily;

        .floorplan-search-field-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .floorplan-search-button {
                width: 2.5rem;
                height: 2.5rem;
                padding: 0.625rem;
                background-color: $themeColor2;
                color: $themeMono1;
                border-bottom-right-radius: 3px;
                border-top-right-radius: 3px;
                transition: 0.3s;

                &:hover {
                    background-color: $themeColor7;
                }

                &:disabled {
                    background-color: $themeMono3;
                }
            }

            .floorplan-search-field {
                width: 100%;

                input[data-testid='floorplan-search-field'] {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }

        .radio-button-selector {
            height: unset;
            padding: unset;

            .MuiFormGroup-root {
                width: 100%;
                justify-content: space-between;
            }

            .MuiTypography-root {
                font-size: 0.875rem;
                font-weight: 400;
                font-family: $fontFamily;
            }

            .radio-button {
                padding-right: 0.25rem;
            }

            label {
                margin-right: unset;
                color: $themeMono11;
            }
        }
    }

    &-title {
        color: $themeMono11;
        font-weight: 300;
        line-height: 1;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }
}
