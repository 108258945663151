@import "./src/scss/index";
$dimentions: 1.5625rem;
$margin: 1.09375rem;

.siteNavigation {
    min-width: 3.75rem;
    width: 3.75rem;

    background: $navBackground;
    color: $themeMono1;

    overflow-x: hidden;
    transition: all 0.2s ease-in-out;

    &.open {
        min-width: 12.85rem;

        ul li a span {
            opacity: 1;
            transition: opacity 0.1s linear 0.1s;
        }
    }

    .iconOnly {
        margin: $margin;
        width: $dimentions;
        height: $dimentions;
        color: inherit;

        &:active,
        &:focus {
            outline: 0;
        }

        svg {
            margin: 0;
            width: $dimentions;
            // Required to prevent massive spacing on IE11
            height: $dimentions;
        }
    }

    ul {
        @extend .flexCol;

        li {
            border-bottom: 1px solid $themeColor24;

            a {
                @extend .flexRow;
                align-items: center;
                color: $navColor;
                fill: $themeMono14;
                background-color: $navLinkBackground;

                &:hover,
                &.active {
                    color: $themeMono1;
                    fill: $themeMono1;

                    &:hover {
                        background-color: $navLinkBackgroundHover;
                    }

                    &.active {
                        background: $navLinkBackgroundActive;
                    }
                }

                svg {
                    color: inherit;
                    width: $dimentions;
                    // Required to prevent massive spacing on IE11
                    height: $dimentions;
                    margin: $margin;
                    flex-shrink: 0;
                    stroke-width: 5%;
                }

                span {
                    color: inherit;
                    opacity: 0;
                    transition: opacity 0.1s linear;
                }
            }
        }
    }
}
