@import "./src/scss/index";
.report-filter-column {
    display: flex;
    align-items: center;

    .report-filter-icon-container {
        display: flex;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;

        .report-filter-icon {
            fill: $themeMono15;

            &:hover {
                fill: $themeColor12;
            }

            &.applied {
                fill: $positive;
            }
        }
    }
}
