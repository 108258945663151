@import "./src/scss/index";
.account_settings {
    @extend .flexCol;

    .row {
        @extend .flexRowWrap;
        margin: 0 0 0 -1.5rem;
        min-width: toRem(440);
        max-width: toRem(722);

        .inputWrapper {
            min-width: 17.5rem;
            margin: 1.5rem 0 0 1.5rem;

            .openUpdatePassword {
                text-transform: none;
                padding: 0.5rem 0.75rem;
            }
        }
    }
}
