@import './normalize';
@import './variables';
@import './utilities';
@import './typography';

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    @extend .flexCol;
    color: $bodyCopyColor;
    overflow: hidden;
}

hr {
    border-top: 1px solid $themeMono5;
}

#root {
    @extend .flexCol;
    flex: 1;
}

.hidden {
    display: none;
}

//Gets rid of the annoying outline for mouse users but preserves it for keyboard users, and is ignored by browsers that don’t support :focus-visible.
:focus:not(:focus-visible) {
    outline: none;
}
