@import "./src/scss/index";
 .company-search-result {
    height: 100%;
    display: flex;
    flex-direction: column;

   .result-count {
     display: flex;
     align-items: flex-start;
     font-size: 0.75rem;
     margin-top: 0.5rem;

     .count {
       font-weight: $fontWeightBold;
       color: $themeMono17;
     }

     .label {
       color: $themeMono10;
     }
   }

   .search-result {
     border-top: 1px solid $themeMono6;
     border-bottom: 1px solid $themeMono6;
     overflow-y: auto;
     color: $themeMono17;

     .placeholder {
       margin: 2.75rem 4rem;
       font-size: 1rem;
       color: $themeMono11;
       font-weight: $fontWeightLight;
       width: 14.5rem;
       overflow: hidden;
       text-overflow: ellipsis;
     }
   }
 }
