@import "./src/scss/index";
.advancedFilters {
    @extend .flexCol;
    flex: 1 1 auto;

    .reset {
        background: none;
        font-size: 0.75rem;
        color: $themeColor12;
        text-transform: none;
        padding: 0;
        margin: 0 0 0.75rem auto;

        svg {
            width: 0.5rem;
            height: 0.75rem;
            margin-right: 0.3125rem;
        }

        &:hover {
            background: none;
            color: $themeColor13;
        }
    }

    .filter {
        @extend .flexRow;
        justify-content: space-between;
        width: 20.625rem;
        padding: 1rem 0.875rem;
        color: $themeMono11;
        background: $themeMono2;
        font-size: 1rem;
        margin-bottom: 0.25rem;
        align-items: center;

        .toggle {
            @extend .flexRow;
            font-weight: $fontWeight;

            input:checked + label:after {
                left: 1.25rem;
            }

            label {
                &::before {
                    margin-top: 0;
                    width: 2.5rem;
                    height: 1.375rem;
                }

                &::after {
                    height: 1.125rem;
                    width: 1.125rem;
                    bottom: 0.125rem;
                    left: 0.125rem;
                }
            }
        }

        &:first-of-type {
            border-top: 1px solid $themeMono6;
        }

        &:last-of-type {
            margin-bottom: 1rem;
            border-bottom: 1px solid $themeMono6;
        }

        .multipleFilters {
            @extend .flexRow;
            align-items: center;

            .title {
                margin-right: 0.875rem;
            }
        }
    }

    .option {
        @extend .flexRow;
        align-items: center;
        height: 3.125rem;
        border-bottom: 1px solid $themeMono6;
        padding-left: 0.875rem;

        .optionName {
            font-size: 0.875rem;
            color: $themeMono11;
            margin-left: 0.625rem;

            &.checked {
                font-weight: bold;
            }
        }
    }

    input[type='checkbox'] {
        position: relative;
        width: 0.75rem;
        height: 0.75rem;
        appearance: none;
        outline: 1px solid $themeMono6;
        outline-offset: 0.125rem;
        border-radius: 0.125rem;
        cursor: pointer;

        &:hover {
            outline-color: $themeColor12;
        }

        &:checked {
            outline-color: $themeColor12;
            background-color: $themeColor12;
        }
    }

    .arrowIcon {
        transition: all 0.4s ease;

        &.isOpen {
            transform: rotateZ(-180deg);
        }
    }

    .completeAction {
        margin: auto auto 0;
    }
}
