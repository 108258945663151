@import "./src/scss/index";
.page-visitor-booking {
  position: relative;
  height: calc(100vh - #{$headerHeight});

  .no-type-selected{
    background-color: $themeMono16;
    height: 100%;
    width: 100%;
  }
}
