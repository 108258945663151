@import "./src/scss/index";
.report-filter-container {
  .report-filter-search {
    width: 90%;
    margin-bottom: 2rem;
  }

  .report-filter-content-scroll {
    .option {
      &:first-child {
        border-top: 1px solid $themeMono6;
      }

      .optionName {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
      }
    }

    .report-filter-no-result {
      display: flex;
      justify-content: center;

      &-message {
        font-size: 1rem;
        color: $themeMono11;
      }
    }
  }
}
