@import "./src/scss/index";
.header-steps {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.5rem;
  width: 100%;

  .header-step-item {
    width: 100%;
    display: contents;

    .circle-n-name {
      display: flex;
      justify-content: center;

      .circle {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        border: 3px solid;
        border-color: $themeColor34;
        background-color: $themeColor35;
      }

      .name {
        font-size: 0.8125rem;
        color: $themeColor35;
        position: absolute;
        margin-top: 1.5rem;
        white-space: nowrap;
      }
    }

    .line {
      border-top: 2px solid;
      width: 100%;
      min-width: 3rem;

      max-width: 8rem;
      border-color: $themeColor35;
    }

    &.selected {
      .name {
        color: $themeMono1;

      }
      .circle {
        background-color: $themeColor16;
        border-color: $themeMono1;
      }

      .line {
        border-color: $themeMono1;
      }

      .arrow-up {
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        border-bottom: 0.5rem solid $themeColor38;
        position: absolute;
        margin-top:2.75rem;
      }
    }

    &.accessible {
      .circle {
        background-color: $themeMono1;
        border-color: $themeColor35;
      }
    }
  }
}
