@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

$fontFamily: 'Lato', sans-serif;
$fontWeightLight: 300;
$fontWeight: 400;
$fontWeightBold: 700;

// line spacing
$lineHeightLabel: 0.75rem;

// font sizes
$fontSizeLabel: 0.875rem;

body {
    font-family: $fontFamily;
    font-weight: $fontWeight;
}
