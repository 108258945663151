@import "./src/scss/index";
.logins-report {
  position: relative;
  background-color: $themeMono16;

  .logins-report-title {
    p {
      width: auto;
      white-space: nowrap;
    }
    .edit-btn-n-date-range {
      display: flex;
      align-items: center;

      .date-range-picker {
        margin: 0;

        .date-range-picker-field {
          margin-left: 4rem;

          &:not(:hover) {
            background-color: $themeMono1;
          }
        }
      }
      .edit-btn {
        height: 2rem;
        margin-left: 2rem;
        margin-top: 0.5rem;
      }
    }
  }

  .filter-options {
    display: flex;
    align-items: flex-end;
    padding: 1.5rem 2rem;

    .inputWrapper{
      margin: 0 1rem 0 0;
    }

    .apply-btn{
      height: 2rem;
    }

    .side-panel-input {
      @extend .flexRow;
      width: 15rem;
      height: 2rem;
      background: $themeColor4;
      border-radius: toRem(3);
      border: $inputBorder;
      justify-content: flex-start;
      align-items: center;
      padding-left: toRem(12);
      color: $themeMono12;
      font-size: toRem(15);
      margin-top: toRem(9);
      cursor: pointer;

      &:hover,
      &:focus {
        background: $themeColor20;
        border-color: $inputBorderHover;
        outline: none;

        .arrowIcon {
          color: $themeColor7;
        }
      }

      .cross-icon {
        width: 1rem;
        margin-left: auto;
        margin-right: 0.5rem;
      }

      .selected-item{
        width: 12rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .data-table {
    margin-left: 2rem;
    max-height: calc(100% - 15.25rem);
  }

  .sliding-panel {
    width: 27.5rem;
    overflow-y: hidden;

    h1 {
      margin-bottom: 1.5rem;
    }

    .content {
      justify-content: start;
      width: 100%;
    }
  }
}
