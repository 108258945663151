@import "./src/scss/index";
.pageMyAccount {
    overflow: hidden;
    height: calc(100vh - #{$headerHeight});

    .pageMyAccountContent {
        overflow: hidden;
        @extend .flexCol;
        flex-grow: 1;
        position: relative;

        form {
            @extend .flexCol;
            justify-content: space-between;
            height: 100%;
            padding-bottom: $actionButtonPanelHeight;

            .content {
                overflow-y: auto;
                padding: 2.5rem 1.875rem 0;

                h2 {
                    font-size: 1.125rem;
                    line-height: 1.125rem;
                    font-weight: $fontWeightBold;
                }

                hr {
                    margin: 1.875rem 0;
                }
            }
        }
    }
}
