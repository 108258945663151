@import "./src/scss/index";
.resource-utilisation-report {
  position: relative;
  background-color: $themeMono16;

  .resource-utilisation-title{
    p{
      width: auto;
      white-space: nowrap;
    }

    .edit-btn-n-date-range {
      display: flex;
      align-items: center;

      .date-range-picker {
        margin: 0;

        .date-range-picker-field {
          margin-left: 4rem;

          &:not(:hover) {
            background-color: $themeMono1;
          }
        }
      }

      .edit-btn {
        height: 2rem;
        margin-left: 2rem;
        margin-top: 0.5rem;
      }
    }
  }

  .filter-options {
    display: flex;
    align-items: flex-end;
    padding: 1.5rem 2rem;

    .inputWrapper{
      margin: 0 1rem 0 0;
      width: 13rem;

      .select__control {
        min-height: 2rem;
        height: 2rem;

        .select__value-container{
          height: 2rem;
        }
      }
    }

    .apply-btn{
      height: 2rem;
    }
  }

  .sliding-panel {
    width: 27.5rem;
    overflow-y: hidden;


    h1 {
      margin-bottom: 1.5rem;
    }

    .content {
      justify-content: start;
      width: 100%;
    }
  }

  .data-table{
    margin-left: 2rem;
    max-height: calc(100% - 15.25rem);
  }
}
