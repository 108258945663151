@import "./src/scss/index";
.mainLayout {
    @extend .flexRow;
    flex: 1;
    overflow-x: hidden;
    main {
        @extend .flexCol;
        overflow-x: hidden;
        flex-grow: 1;

        // > .header {
        //     @extend .flexRow;
        //     align-items: center;
        //     padding: 1rem 2rem;
        //     justify-content: space-between;

        //     background: $themeColor1;
        //     color: $themeMono1;

        //     h1 {
        //         font-weight: $fontWeightLight;
        //         font-size: 2rem;

        //         span {
        //             font-weight: 400;
        //         }
        //     }
        // }
    }
}
