@import "./src/scss/index";
.search-result-item {
  height: 3.375rem;
  padding-left: 0.75rem;
  padding-right: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(2n+1) {
    background-color: $themeMono18;
  }

  &:hover {
    background-color: $themeColor4;
    cursor: pointer;
  }

  .name-email {
    color: $themeMono17;
    width: 18rem;

    .name, .email{
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
    }

    .name {
      font-size: 0.875rem;
      font-weight: $fontWeightBold;
    }

    .email {
      font-size: 0.8125rem;
    }
  }

  .visitor-type {
    height: 1.3125rem;
    width: 1rem;
    color: $themeColor7;
  }

  &.selected {
    background-color: $themeColor7;

    &:hover {
      background-color: $themeColor7;
    }

    .name-email, .visitor-type {
      color: $themeMono1;
    }
  }
}
