@import "./src/scss/index";
.multi-resource-booking-page{
  background-color: $themeMono18;
  display: flex;
  height: calc(100vh - #{$headerHeight});
  position: relative;
  overflow: hidden;

  .multi-resource-booking-header{
    height: 5rem;
  }

  .mrb-site-selector{
    margin: 2rem 0;
  }

  .main-content{
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 18rem);;
    padding-left: 2rem;
  }
}
