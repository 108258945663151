@import "./src/scss/index";
.resourceInfo {
    @extend .flexCol;
    flex: 1 1 auto;

    .pageHeader {
        margin-bottom: 1rem;
    }

    .resourceInfoContent {
        display: flex;

        .resourceImage {
            margin-right: toRem(40);

            img {
                max-width: 30rem;
            }
        }
    }
}
