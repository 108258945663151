@import "./src/scss/index";
.siteHeader {
    @extend .flexRow;
    height: $headerHeight;
    align-items: center;
    position: relative;
    z-index: 5;

    background: $siteHeaderBackground;
    color: $themeMono1;
    box-shadow: 0px 2px 6px $siteHeaderBoxShadow;

    .logoWrapper {
        background: $themeMono1;
        height: $headerHeight;
        min-width: 12.85rem;
        width: 12.85rem;
        max-width: 12.85rem;
        @extend .flexRow;
        justify-content: center;
        align-items: center;

        svg {
            height: 1.9rem;
        }
    }

    .userProfileSummary {
        @extend .flexCol;
        margin: 0 1rem;

        .userProfileBadge {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            outline: 0;
            color: $themeColor1;

            &:focus {
                outline: 0;
            }

            &.large {
                width: 4.5rem;
                height: 4.5rem;
            }
        }

        .userProfileMenu {
            background: $themeMono1;
            border-radius: 5px;
            box-shadow: $profileShadow;
            color: $themeMono13;
            display: none;
            font-size: 0.8rem;
            height: 100%;
            min-height: 20rem;
            padding: 2.25rem 1rem 1.875rem;
            position: absolute;
            right: 1rem;
            top: 0.7rem;
            min-width: 15.625rem;
            max-width: 40.625rem;

            &.open {
                @extend .flexCol;
                align-items: center;
            }

            .iconOnly {
                align-self: flex-start;
                color: $themeMono10;
                cursor: pointer;
                height: 1.875rem;
                margin: 0;
                position: absolute;
                width: 1.875rem;

                top: 0.75rem;
                left: 0.75rem;

                > svg {
                    height: 1.125rem;
                    width: 1.125rem;
                }
            }

            .userProfileBadge {
                cursor: default;
                font-size: 1.3rem;
                width: 3.8rem;
                height: 3.8rem;
            }
            .name {
                font-size: 1.25rem;
                color: $themeMono12;
                margin-top: 1.375rem;
                line-height: 1.5rem;
            }

            .tenant {
                font-size: 0.875rem;
                color: $themeMono11;

                & + .linkAsButton {
                    margin-top: auto;
                }
            }

            // @todo: this can be removed if we are no longer having email in the roundel
            .email {
                margin: 0.8rem;
                font-size: 0.9rem;
                color: $themeColor12;
            }

            .linkAsButton {
                @extend .flexRow;
                justify-content: center;
                align-items: center;
                border: 1px solid currentColor;
                border-radius: $borderRadius;
                padding: 0.5rem 2rem;
                width: 11.25rem;
                font-size: 0.9375rem;
                transition: background-color 0.25s linear;

                &.logout {
                    color: $themeColor13;
                }

                &.myAccount {
                    background-color: $themeColor12;
                    color: $themeMono1;
                }

                &:hover {
                    cursor: pointer;
                    background-color: $themeColor13;
                    color: $themeMono1;
                }

                & + .linkAsButton {
                    margin-top: 0.625rem;
                }
            }
        }
    }
}
