@import "./src/scss/index";
.pageHeader {
    margin-bottom: 0.5rem;

    h2 {
        font-weight: $fontWeightBold;
        font-size: 20px;
    }

    .resourcePin {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.pageHeaderSlider {
    padding: 0 30px;
}
