@import "./src/scss/index";
.drop-down {
    min-width: 5rem;
    width: auto;
    background-color: $themeColor4;

    &.MuiInputBase-root:before,
    &.MuiInputBase-root:after {
        content: none;
    }

    .MuiInput-input {
        &:focus {
            background-color: unset;
        }
    }

    &:hover > svg {
        fill: $themeColor33;
    }

    .MuiSelect-icon {
        transition: transform 0.4s ease;
        fill: $themeColor12;
        top: 35%;
        margin-right: 0.5rem;

        &.Mui-disabled {
            fill: $themeMono10;
        }

        polygon {
            fill: inherit;
        }
    }

    &.error {
        .MuiSelect-icon {
            fill: $themeColor6;
        }
    }
}

.paper-container {
    &.large {
        height: 12.5rem;
    }

    .MuiMenuItem-root {
        font-family: $fontFamily;
    }
}
