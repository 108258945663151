@import "./src/scss/index";
.booking-view-item {
    display: flex;
    margin: 0 5rem 0.625rem 5rem;
    height: 4.625rem;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 7px;
    box-shadow: 0 1px 4px 1px $themeMono8;
    min-width: 57rem;
    color: $themeMono19;

    .checkout-confirmation {
        max-width: 40rem;

        .multiday-dialog-buttons {
            padding: 0 4rem 1rem 4rem;

            .button {
                width: 27rem;
                margin-bottom: 0.75rem;
            }
        }
    }

    .label-time {
        .label {
            font-size: 0.625rem;

            &.checked-in {
                color: $themeColor31;
            }

            &.checked-out {
                color: $themeColor6;
            }
        }

        .time {
            font-size: 1.125rem;
            font-weight: $fontWeightBold;
        }
    }

    .visitor-icon {
        height: 1.75rem;
        width: 1.25rem;
        margin-right: 1.25rem;
        margin-left: -2.5rem;
        color: $themeColor7;

        &.internal {
            color: $themeColor7;
        }

        &.external {
            color: $themeColor16;
        }
    }

    .visitor,
    .host {
        display: flex;
        align-items: center;

        .name-n-company,
        .name-n-phone {
            width: 100%;
            height: 2.875rem;

            .name,
            .company,
            .phone {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .name {
        font-size: 0.9375rem;
        font-weight: $fontWeightBold;
        color: $themeColor12;
        max-width: 100%;
        width: fit-content;

        &:hover {
            color: $themeColor7;
            cursor: pointer;
        }
    }

    .column-item {
        flex-basis: 20%;
        min-width: 10rem;
        margin-left: 1.5rem;
        white-space: nowrap;
    }

    .booking-type-indicator {
        width: 1.875rem;
        min-width: 1.875rem;
        border-radius: 7px 0 0 7px;
        height: 100%;

        &.internal {
            background-color: $themeColor7;
        }

        &.external {
            background-color: $themeColor16;
        }
    }

    .booking-item-buttons {
        display: flex;
        width: 20rem;
        justify-content: flex-end;
        margin-left: auto;

        .item-button {
            width: 6.25rem;
            min-width: 6.25rem;
            height: 3.75rem;
            flex-direction: column;
            border-radius: 5px;
            display: flex;
            align-items: center;
            text-align: center;
            padding-top: 0.8125rem;
            color: white;
            margin-right: 0.375rem;
            font-size: 11px;

            &:hover {
                &:not(.disabled) {
                    cursor: pointer;
                }
            }

            &.checkin-btn {
                background-color: #43c468;

                &:hover {
                    &:not(.disabled) {
                        background-color: #009966;
                    }
                }
            }

            &.checkout-btn {
                background-color: #d24414;

                &:hover {
                    &:not(.disabled) {
                        background-color: $themeColor18;
                    }
                }
            }

            &.cancel-visit-btn {
                background-color: #e8e8e8;
                color: $themeMono17;
                border: 1px solid transparent;

                &:hover {
                    &:not(.disabled) {
                        background-color: $themeMono1;
                        border: 1px solid $themeColor6;
                    }
                }
            }

            &.edit-visit-btn {
                background-color: #e8e8e8;
                color: $themeMono17;
                border: 1px solid transparent;

                &:hover {
                    &:not(.disabled) {
                        background-color: $themeMono1;
                        border: 1px solid $themeColor12;
                    }
                }
            }

            svg {
                fill: white;
                height: 1.25rem;
                width: 2rem;
                margin-bottom: 0.375rem;
            }

            &.disabled {
                color: $themeMono15;
                background-color: $themeMono3;

                svg {
                    fill: $themeMono15;
                }
            }
        }
    }
}
