@import "./src/scss/index";
.radio-button-selector {
    height: 4.375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.875rem;

    label {
        margin-right: 3rem;

        &.Mui-disabled {
            .checked-item {
                svg circle {
                    fill: $themeMono20;
                }
            }
        }
    }

    .radio-buttons-title {
        font-size: 1.0625rem;
        font-weight: $fontWeightBold;
        padding-right: 1.75rem;
    }

    .radio-button {
        padding: 0.875rem;
        &:hover {
            background-color: transparent !important;
        }

        .unchecked-item,
        .checked-item {
            svg {
                height: 34px;
                width: 34px;
            }
        }

        .unchecked-item {
            svg circle {
                stroke: $themeMono10;
            }
        }

        .MuiSvgIcon-root {
            color: $themeColor12;
        }
    }
}
