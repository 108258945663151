@import "./src/scss/index";
.booking-info {
    .repeat-info {
        margin-left: 1rem;
        font-size: 0.875rem;
        color: $themeMono11;
        line-height: 1rem;

        &.isDisabled {
            margin: 0.625rem 0;
        }
    }

    .repeat-button {
        max-width: 4.375rem;
        max-height: 2.5rem;
        display: flex;
        flex-direction: row-reverse;
        margin-top: 0.563rem;
        text-transform: none;
        padding: 0.375rem 0.75rem;

        span {
            padding-right: 0.5rem;
        }
    }

    .inputWrapper.horizontal {
        margin-top: 1.5rem;
        justify-content: flex-start;

        .requiredLabel {
            position: static;
        }

        .attendees {
            margin-right: 2.5rem;
        }

        .label {
            font-size: 1rem;
        }
    }

    .toggle {
        label {
            color: $themeColor13;
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .edit-repeat-container {
        @extend .flexRow;
        align-items: center;
        margin-top: 0.563rem;

        button {
            svg {
                height: inherit;
            }
        }

        .edit-repeat-button {
            padding: 0.5rem;
            border-radius: 50%;
        }

        .message {
            font-size: 0.75rem;
            color: $themeMono10;
        }
    }

    .repeat-icon-svg,
    .edit-repeat-svg {
        fill: $themeMono1;
    }
}
