.flexRow {
    display: flex;
    flex-direction: row;
}

.flexRowWrap {
    display: flex;
    flex-flow: row wrap;
}

.flexCol {
    display: flex;
    flex-direction: column;
}

// @todo: needs to be refactored to use rem's and to support our validation needs (needs to be positioned correctly and have the appropriate color etc.)
// NOTE: oroginal retrieved from: http://hackingui.com/front-end/10-best-scss-utilities/
@mixin alerted() {
    &:before {
        content: '';
        position: absolute;
        top: 6px;
        right: 6px;
        height: 8px;
        width: 8px;
        border-radius: 10px;
        background-color: gold;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        background-color: transparent;
        border: solid gold;
        border-width: 2px; // animates
        animation-name: alertMe;
    }
}

@mixin noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

@mixin dashedHorizontal($color, $spacing, $dashToSpacingRatio, $position: bottom, $borderWidth: 1px) {
    background-position: $position;
    background-repeat: repeat-x;
    background-size: $spacing $borderWidth;  
    background-image: linear-gradient(to right, $color $dashToSpacingRatio, rgba(255, 255, 255, 0) 0%);
    background-image: -webkit-linear-gradient(to right, $color $dashToSpacingRatio, rgba(255, 255, 255, 0) 0%);
  }

  @mixin dashedVertical($color, $spacing, $dashToSpacingRatio, $position: right, $borderWidth: 1px) {
    background-position: $position;
    background-repeat: repeat-y;
    background-size: $borderWidth $spacing;  
    background-image: linear-gradient($color $dashToSpacingRatio, rgba(255, 255, 255, 0) 0%);
    background-image: -webkit-linear-gradient($color $dashToSpacingRatio, rgba(255, 255, 255, 0) 0%);
  }

@keyframes alertMe {
    from {
        border-width: 3px;
        border-color: gold;
    }
    to {
        border-width: 0;
        border-color: rgba(gold, 0.1);
    }
}

@function toRem($px) {
    $result: calc(#{$px} / 16);
    @return  calc(#{$result} * 1rem);
}
