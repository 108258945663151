@import "./src/scss/index";
.duration-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $themeColor1;
    color: $themeMono1;
    border-right: 1px solid $themeMono1;
    padding: toRem(4) toRem(10);
    font-size: toRem(14);
    width: 10%;
    cursor: pointer;

    .arrow-selector {
        background: url(../../../../../../assets/icons/siteSelector/selectorArrowOff.svg) no-repeat;
        width: 1.1875rem;
        height: 1.125rem;
    }

    .duration-container {
        padding: 0 1rem;
        display: flex;
        align-items: center;

        .duration {
            width: max-content;
        }

        .time-icon {
            width: 1rem;
            height: 1rem;
            color: $themeColor1;
            margin-right: 0.3rem;
        }
    }
}
