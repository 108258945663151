@import "./src/scss/index";
.aboutInfo {
    @extend .flexRow;
    flex-flow: row !important;
    padding: 1.375rem 0;

    img {
        width: 1.25rem;
        height: 1.25rem;
    }

    .content {
        @extend .flexCol;
        margin-left: toRem(10);
        max-width: 100%;

        .title {
            font-size: $fontSizeLabel;
            line-height: toRem(14);
            margin-bottom: toRem(12);
            font-weight: $fontWeight;
        }

        .body {
            font-size: toRem(12);
            color: $themeMono11;
            font-weight: $fontWeight;
            display: inline-block;
            line-height: 1rem;
            margin: 0 1rem 0 0;
        }


    }
}

.bottom-border {
    border-bottom: 1px solid $themeMono6;
}