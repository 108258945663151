@import "./src/scss/index";
@import url('https://use.fontawesome.com/releases/v5.13.0/css/all.css');
.inputWrapper {
    @extend .flexCol;
    align-items: flex-start;

    & + .inputWrapper {
        margin-top: 1.5rem;
    }

    label {
        color: inherit;
        font-size: inherit;
        line-height: 1;

        &.hidden {
            display: none;

            + input {
                margin-top: 0;
            }
        }

        & + input,
        & + textarea {
            margin-top: toRem(9);
        }
    }

    input {
        height: 2.5rem;
        line-height: 1;

        &:focus::placeholder {
            color: transparent;
        }

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            content: url('../../../assets/icons/CrossCircle.svg');
            cursor: pointer;
            height: 1rem;
        }
    }

    textarea {
        resize: none;
        padding: 0.4rem 0.4rem 0.4rem 0.75rem;
        width: 100%;
        line-height: 1.5rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .drop-down,
    input,
    textarea {
        border-radius: $borderRadius;
        border: $inputBorder;
        color: $themeMono12;
        font-size: toRem(15);
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        width: 100%;

        &:not(.error) {
            &:disabled,
            &.Mui-disabled {
                background-color: $inputBackgroundDisabled;
                border-color: $inputBorderDisabled;
                color: $inputColorDisabled;

                &[value] {
                    background-color: $inputBackgroundDisabled;
                    border-color: $inputBorderDisabled;
                }
            }

            &:hover {
                border-color: $inputBorderHover;
                &.disabled,
                &.Mui-disabled,
                &:disabled {
                    border-color: $inputBorderDisabled;
                }
            }

            &.hasValue {
                background-color: $inputText;
                border-color: $inputBorder;
                &:not(:disabled) {
                    &:hover {
                        background-color: $themeColor20;
                    }
                }
            }

            &:focus {
                outline: 0;
                border-color: $inputBorderFocus;
                background-color: $inputText;
            }

            &:focus-within {
                outline: initial;
            }
        }

        &.error {
            border-color: $themeColor21;
            background-color: $themeColor22 !important;

            &:focus {
                outline: 0;
            }

            &:focus-within {
                outline: initial;
            }
        }
    }
}

.react-select-container {
    width: 100%;

    .react-select__value-container {
        cursor: pointer;

        input {
            cursor: pointer;
        }

        &.error {
            border-color: $themeColor21;
            background-color: $themeColor22 !important;

            &:focus {
                outline: 0;
            }

            &:focus-within {
                outline: initial;
            }
        }
    }
}


.react-multiSelect-container {
    width: 100%;

    .react-multiSelect__value-container {
        cursor: pointer;

        input {
            cursor: pointer;
        }
        
        &.error {
            border-color: $themeColor21;
            background-color: $themeColor22 !important;

            &:focus {
                outline: 0;
            }

            &:focus-within {
                outline: initial;
            }
        }
    }
}
