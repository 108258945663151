@import "./src/scss/index";
.floorPlan__container {
    position: relative;
    overflow: auto;
    height: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.5rem;

    &--floorplan {
        position: relative;
        
        .searchedDeskWrapper{
            z-index: 5;
        }
        
        .searchedRoomWrapper{
            z-index: 5;
        }

        .searchedDesk {
            border-radius: 50%;
        }

        .searchedRoom {
            border-radius: 3px;
        }

        img {
            user-select: none;
            max-width: initial;
        }

        &--pinWrapper {
            position: absolute;
            transform: translate(-50%, -50%);

            &:hover {
                z-index: 5;
                .tooltipWrapper {
                    @extend .flexRow;
                }
            }

            .isHighlighting {
                @extend .flexRow;
            }
        }

        &--pin {
            position: absolute;

            &--available {
                color: $themeColor31;
                cursor: pointer;
            }

            &--booked-in-the-past {
                color: $themeColor23;
                cursor: pointer;
            }

            &--booked {
                color: $themeColor6;
                cursor: pointer;
            }

            &--on-behalf-of-internal {
                color: $themeColor6;
                cursor: pointer;
            }

            &--mybooking {
                color: $themeColor16;
                cursor: pointer;
            }

            &--on-behalf-of-me {
                color: $themeColor16;
                cursor: pointer;
            }

            &--unavailable {
                color: $themeColor23;
                cursor: pointer;
            }

            &--unavailable_COVID {
                color: $themeColor19;
            }

            &--not-working-time {
                color: $themeColor23;
                cursor: pointer;
            }

            &--restricted {
                color: $themeColor23;
                cursor: pointer;
            }
            &--is-private {
                color: $themeMono13;
                cursor: pointer;
            }
        }

        &--booked {
            color: $themeColor6;
            cursor: pointer;
        }

        &--mybooking {
            color: $themeColor16;
            cursor: pointer;
        }
    }
}
