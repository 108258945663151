@import "./src/scss/index";
.header-icon-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 2.5rem;

    svg {
        width: 2.125rem;
        height: 2.125rem;
    }

    &:hover {
        cursor: pointer;
    }

    .MuiButtonBase-root {
        height: 2.125rem;
        width: 2.125rem;
        padding: 0;
    }

    .header-icon-button-label {
        margin-top: 0.25rem;
        font-size: 0.625rem;
        text-align: center;
        color: $themeMono1;
        font-weight: $fontWeightLight;
    }
}
