@import "./src/scss/index";
.bookings-header {
  display: flex;
  align-items: center;
  height: 1.75rem;
  width: 100%;
  padding: 0 5rem;
  background-color: $themeMono2;
  position: relative;
  box-shadow: 0 3px 4px 0 $themeMono8;

  .header-item {
    margin-left: 1.5rem;
    font-size: 0.875rem;
    font-weight: $fontWeightBold;;
    color: black;
    flex-basis: 20%;
    min-width: 10rem;

    &:first-child{
      margin-left: 3.375rem;

    }

    &:hover {
      color: $themeColor12;
      cursor: pointer;
    }
  }

  .header-item +.header-item {

  }
}
