@import "./src/scss/index";
.date-time-header{
  display: flex;
  align-items: center;
  min-height: 4rem;
  background-color: $themeColor38;
  padding-left: 2rem;

  .date-time .dateTimePickerField {
    margin: 0;
    background: $themeMono1;
  }

  .timezone{
    margin-left: 1rem;
  }
}
