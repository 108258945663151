@import "./src/scss/index";
.pageBookResource {
    overflow: hidden;
    /* @todo: Need to refactor to remove calculation */
    height: calc(100vh - #{$headerHeight});

    .pageBookReourceContent {
        @extend .flexCol;
        flex-grow: 1;
        overflow: hidden;
        position: relative;

        .create-multi-resource-booking-button {
            visibility: hidden;

            &:hover {
                background-color: $themeColor16;
            }
        }

        .tabs-form-wrapper {
            flex-grow: 1;
            overflow: hidden;
        }

        .booking-info,
        .series-info {
            @extend .flexRow;
            width: 100%;

            .formGroup > .inputWrapper:last-child {
                padding-bottom: 1rem;
            }

            .attendees .drop-down {
                width: 5rem;
            }

            .obo-person-search,
            .person-search {
                margin-top: 1.5rem;
            }
        }

        .bookingHistory {
            width: 100%;

            .geoStructureDetails {
                @extend .flexRow;
                justify-content: space-between;
                padding-bottom: 1.875rem;

                .resource {
                    font-weight: $fontWeightBold;
                }

                .area {
                    @extend .flexRow;

                    svg {
                        align-self: center;
                        color: $themeMono12;
                        width: 1.2rem;
                        height: 1.2rem;
                        margin-right: 0.625rem;
                        stroke-width: 5%;
                    }
                }
            }
        }
    }
}
