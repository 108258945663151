@import "./src/scss/index";
.alertDialog {
    @extend .flexCol;
    align-items: center;
    background-color: $themeMono1;
    border-radius: $borderRadius;
    box-shadow: $dialogShadow;
    color: $themeMono12;
    justify-content: center;
    left: 50%;
    max-width: 31.25rem;
    min-width: 31.25rem;
    padding: 2rem 2rem;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    & > svg {
        color: $negative;
        height: 3.75rem;
        width: 3.75rem;

        & + .message {
            margin-top: 1.25rem;
        }
    }

    h1 {
        font-size: 1.25rem;
        font-weight: 700;

        & + p {
            margin-top: 0.5rem;
        }
    }

    p {
        font-size: 1rem;
    }

    .iconOnly {
        align-items: center;
        background-color: transparent;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        right: 1rem;
        top: 1rem;
        position: absolute;

        // Give us a slightly bigger hit area
        width: 2rem;
        height: 2rem;

        &:hover > svg {
            color: $inputBorderHover;
        }

        &:active {
            outline: 0;
        }

        svg {
            color: $themeMono12;
            // Sized to match the designs
            height: 1.06rem;
            width: 1.06rem;
        }
    }
}
