@import "./src/scss/index";
.account_personalDetails {
    @extend .flexCol;

    .row {
        @extend .flexRowWrap;
        margin: 0 0 0 -1.5rem;
        min-width: toRem(440);
        max-width: toRem(722);

        .inputWrapper {
            margin: 1.5rem 0 0 1.5rem;
        }

        .salutation {
            min-width: toRem(130);
            .select__menu {
                z-index: 2;
            }
        }

        .firstName,
        .lastName {
            min-width: toRem(260);
        }

        .email {
            min-width: toRem(414);
        }

        .mobile-input {
            margin-top: 9px;

            .select-container {
                width: toRem(130) !important;

                .select__control {
                    height: toRem(40);
                    min-height: toRem(40);

                    .select__single-value {
                        width: 100%;
                    }
                }
            }

            .select-container + input {
                width: toRem(285) !important;
            }

            .select__menu {
                width: 25.8rem !important;
                top: toRem(44);
                z-index: 2;
            }
        }
    }
}
