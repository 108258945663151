@import "./src/scss/index";
.header {
    @extend .flexRow;
    align-items: center;
    padding: 1.5rem 1.875rem;
    justify-content: space-between;
    background: $themeColor13;
    height: 4.375rem;
    position: relative;

    > p {
        width: 100%;
        color: $themeMono1;
        font-size: 1.875rem;
        font-weight: $fontWeightLight;
    }

    .right-side-items {
        width: 100%;
    }

    &.has-buttons {
        height: 5.625rem;
        padding: 0 1.5rem;
        align-items: start;
        background: linear-gradient(to right, $themeColor2, $themeColor26);
        box-shadow: 0px 3px 4px 0px $themeColor33;

        p {
            font-size: 2.5rem;
            padding-top: 1.25rem;
        }

        .right-side-items {
            padding-top: 1.3125rem;
            display: flex;
            justify-content: flex-end;

            .header-icon-button {
                margin-left: 1.875rem;
            }
        }
    }

    h1 {
        font-weight: $fontWeightLight;
        font-size: 2rem;

        span {
            font-weight: $fontWeight;
        }
    }

    .geographicStructure {
        @extend .flexRow;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        color: $themeMono1;

        svg {
            height: 1.5rem;
            margin-right: 0.5rem;
            width: 2rem;
        }
    }
}
