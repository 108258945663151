@import "./src/scss/index";
.userProfileSummary {
    .name {
        text-align: center;
        word-break: break-word;
        margin-left: -0.5rem;
        .firstName,
        .lastName {
            margin-left: 0.5rem;
            white-space: nowrap;
        }
    }
}
