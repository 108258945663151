@import "./src/scss/index";
$height: 1.3125rem;

.siteHeader {
    .languageSelector {
        @extend .flexCol;
        justify-content: center;
        margin-right: toRem(12);
        margin-left: auto;

        .languageBtn {
            border-radius: 0.1875rem;
        }

        label {
            display: none;
        }

        .react-select-container {
            min-height: $height;
            max-height: $height;

            .react-select__control {
                min-height: $height;
                max-height: $height;
                background: transparent;
                border: none;

                &:after {
                    content: '';
                    position: absolute;
                    background: url('../../assets/icons/languageDropdown/lang-dropdown-arrow-with-bkg.svg') no-repeat;
                    width: 0.875rem;
                    height: 0.75rem;
                    margin-left: 0.625rem;
                    top: 50%;
                    right: 2px;
                    transform: translateY(-50%);
                }

                &--is-focused {
                    box-shadow: none;
                }

                &:hover {
                    background: $themeColor12;
                    border-radius: 0.1875rem;

                    &:after {
                        background: url('../../assets/icons/languageDropdown/lang-dropdown-arrow-with-bkg-hidden.svg')
                            no-repeat;
                    }
                }

                .react-select__value-container {
                    @extend .flexRow;
                    justify-content: center;
                    align-items: center;
                    height: $height;
                    line-height: $height;
                    padding: 0;

                    div:last-child {
                        position: absolute;
                        top: -0.5rem;
                        opacity: 0;
                    }

                    .react-select__single-value {
                        position: relative;
                        color: $themeMono1;
                        font-size: toRem(12);
                        margin: 0;
                        padding: 0 28px 0 10px;
                        max-width: unset;
                    }
                }

                .react-select__indicators {
                    display: none;
                }
            }

            .react-select__menu {
                width: 12.5rem;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
                font-size: toRem(14);
                top: -1rem;
                right: 0;

                &-list {
                    padding: 0;

                    .react-select__option {
                        height: toRem(40);
                        background: $themeMono1;
                        color: $themeMono11;

                        &:last-child {
                            border-radius: 0 0 3px 3px;
                        }

                        &.react-select__option--is-selected {
                            background: $themeColor4;
                            font-weight: $fontWeightBold;
                            color: $themeMono12;
                        }

                        &.react-select__option--is-focused {
                            background: $themeColor13;
                            color: $themeMono1;
                        }
                    }
                }
            }
        }
    }
}
