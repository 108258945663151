@import "./src/scss/index";
.actionButtonsPanel {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background: $actionButtonsBackground;
    border-top: 0.2rem solid $actionButtonsBorder;

    .left-side-elements {
        display: flex;
        align-items: center;
        margin-left: 3rem;
    }

    .action-buttons {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: flex-end;
        height: toRem(70);
        padding: 0 toRem(25);
        justify-content: flex-end;

        button {
            margin: 0 toRem(5);
            min-height: toRem(40);
            min-width: toRem(130);
        }
    }
}
