@import "./src/scss/index";
.pageHelp__content {
    @extend .flexRowWrap;
    color: $themeMono12;
    align-items: stretch;
    flex-grow: 1;
    height: 0;
    overflow-y: auto;

    a {
        color: $themeColor12;
    }

    &--icon {
        width: toRem(100);
        min-height: toRem(100);
        margin-bottom: toRem(16);
    }

    &__content-area {
        @extend .flexCol;
        flex: 1 0 50%;
        text-align: center;
        justify-content: flex-start;
        align-items: center;
        min-width: toRem(400);
        padding: toRem(28) toRem(20);

        h2 {
            font-size: toRem(20);
            line-height: toRem(24);
            margin-bottom: toRem(28);
            font-weight: 700;
        }

        &--logo-content {
            @extend .flexCol;
            align-items: center;
            flex: 0 0 auto;

            img {
                width: toRem(200);
                margin: toRem(16) 0 0 0;
            }
        }

        &--text-content {
            @extend .flexCol;
            align-items: center;
            flex: 1 0 auto;
            color: $themeMono11;
            
            td {
                border: 1px solid black;
                
                &::after {
                    content: ' ';
                }
            }
           
            p > strong {
                font-weight: 700;
                margin-top: toRem(22);
                display: inline-block;
            }

            a:hover {
                text-decoration: underline;
                color: $themeColor7;
            }
        }

        .documentation-link {
            display: inline-block;
            padding: toRem(6) toRem(30);
            color: $themeMono1;
            background-color: $themeColor7;
            border: 1px solid $themeColor7;
            border-radius: $borderRadius;
            margin-top: toRem(24);

            &:hover {
                background-color: $themeColor16;
                border-color: $themeColor16;
            }
        }

        &:first-child {
            background-color: $themeMono2;
        }
    }
}
