@import "./src/scss/index";
.gridFilterPanel {
    @extend .flexRow;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    background: $themeColor27;
    padding: 0 1.1875rem;
    height: 0;

    &.open {
        height: 2.25rem;
        box-shadow: inset 0 4px 12px -7px rgba(0, 0, 0, 0.3);

        .tooltip,
        .filterTitle {
            display: block;
        }
    }

    .tooltip {
        position: absolute;
        z-index: 1;
        top: -0.5rem;
        right: 4.25rem;
        width: 0;
        height: 0;
        border-bottom: 0.5rem solid $themeColor27;
        border-right: 0.5rem solid transparent;
        border-left: 0.5rem solid transparent;
        display: none;
    }

    .filterTitle {
        font-weight: $fontWeightBold;
        color: $themeMono12;
        font-size: 0.75rem;
        margin-right: 0.5em;
        display: none;
    }
}
