@import "./src/scss/index";
.alertBox {
    @extend .flexRow;
    align-items: flex-start;
    padding: 0.5rem 1rem;

    svg {
        width: 3rem;
        margin: 0.25rem 1rem 0 0;
    }
}
