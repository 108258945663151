@import "./src/scss/index";
.mrb-site-selector {
  display: flex;
  svg{
    height: 1.5rem;
  }
  .site-groups{
    width: 20rem;
    margin-right: 2rem;
    overflow-y: auto;

    .site-groups-header{
      display: flex;

      .title{
        margin-left: 1rem;
        color: $themeMono13;
        font-weight: $fontWeightBold
      }
      margin-bottom: 1rem;
    }

    .site-group-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 3rem;
      padding: 0 1rem;
      border-top: 1px solid $themeMono10;
      background-color: $themeMono1;
      cursor: pointer;
      color: $themeMono11;

      .name{
        width: 15rem;
      }

      &:last-child{
        border-bottom: 1px solid $themeMono10;
      }

      &:hover:not(.selected) {
        background: $themeColor4;

        svg{
          height: 1rem;
          color: $themeMono5;
        }
      }

      svg{
        height: 1rem;
        color: $themeMono1;
      }

      &.selected {
        background: $themeColor2;
        color: $themeColor4;
      }
    }
  }

  .sites {
    width: 20rem;

    .sites-header{
      display: flex;

      .title{
        margin-left: 1rem;
        color: $themeMono13;
        font-weight: $fontWeightBold
      }

      margin-bottom: 1rem;
    }

    .site-items {
      overflow-y: auto;
      height: calc(100% - 3rem);

      .site-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 3rem;
        padding: 0 1rem;
        border-top: 1px solid $themeMono10;
        background-color: $themeMono1;
        color: $themeMono11;

        &:last-child {
          border-bottom: 1px solid $themeMono10;
        }
        .name{
          width: 15rem;
        }

        .check-box {
          width: 1.125rem;
          height: 1.125rem;
        }

        input[type="checkbox"] {
          filter: hue-rotate(290deg);;
        }

        &:not(.disabled) {
          cursor: pointer;

          .check-box {
            cursor: pointer;
          }
        }
      }
    }
  }

}
