@import "./src/scss/index";
.loader {
    z-index: 210;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.interstitialContent {
    @extend .flexCol;
    align-items: center;

    svg + h1 {
        color: $themeMono11;
        font-size: 1rem;
        font-weight: $fontWeightBold;
        margin-top: 1.125rem;
    }

    .circle,
    .interstitial_svg__circle {
        animation: rotate linear 1s;
        animation-iteration-count: infinite;
        transform-origin: center;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
