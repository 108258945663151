@import "./src/scss/index";
.summaryIcons {
    @extend .flexRowWrap;
    border-bottom: 1px solid $themeMono6;
    width: 100%;

    .summaryIcon {
        @extend .flexCol;
        align-items: center;
        width: toRem(90);
        margin-bottom: 1rem;

        img {
            width: toRem(54);
            height: toRem(46);
            margin-bottom: 10px;
        }

        span {
            text-align: center;
            font-weight: $fontWeight;
            line-height: $lineHeightLabel;
            font-size: toRem(12);
            color: $themeColor13;
        }
    }

    @media only screen and (max-width: toRem(768)) {
        flex-wrap: wrap;

        .summaryIcon {
            margin-top: 0.625rem;
        }
    }
}
