@import "./src/scss/index";
.updatePassword {
    @extend .flexCol;
    flex: 1 1 auto;

    form {
        @extend .flexCol;
        flex: 1 1 auto;
        justify-content: space-between;
        min-width: 18.75rem;

        .passwordRequirements {
            text-align: right;
            font-size: 0.875rem;
            margin-top: 1.5rem;
            color: $themeMono11;
            
            ul {
                text-indent: 1rem;
            }
        }

        .passwordErrorMessage {
            margin-bottom: 1.5rem;
        }
    }
}
