@import "./src/scss/index";
.dialog {
    @extend .flexCol;
    align-items: center;
    background-color: $themeMono1;
    border-radius: $borderRadius;
    box-shadow: $dialogShadow;
    color: $themeMono12;
    justify-content: center;
    left: 50%;
    padding: 2rem 2rem 1.0625rem 2rem;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 34.75rem;
    z-index: 10;

    & > svg {
        color: $neutral;
        height: 3.75rem;
        width: 3.75rem;

        & + .message {
            margin-top: 1.25rem;
        }
    }

    h1 {
        font-size: 1.25rem;
        font-weight: 700;
    }

    .description{
        padding-top: 0.5rem;
    }

    .buttons {
        margin-top: 1.5rem;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        color: $headerCopyColor;
        padding: 0.9375rem;
        border: none;

        svg {
            height: 1.125rem;
            width: 1.125rem;
        }
    }
}
