@import "./src/scss/index";
.resourceAttributes {
    @extend .flexRow;
    flex-flow: row !important;
    border-bottom: 1px solid $themeMono6;
    padding: 1.375rem 0;

    img {
        width: 1.25rem;
        height: 1.25rem;
    }

    .content {
        @extend .flexCol;
        margin-left: toRem(10);
        max-width: 100%;

        .title {
            font-size: $fontSizeLabel;
            line-height: toRem(14);
            margin-bottom: toRem(12);
            font-weight: $fontWeight;
        }

        .body {
            font-size: toRem(12);
            color: $themeMono11;
            font-weight: $fontWeight;
            display: inline-block;
            line-height: 1rem;
            margin: 0 1rem 0 0;
        }

        & > .attributes-list {
            list-style: none;
            column-count: 1;

            li {
                font-size: toRem(12);
                color: $themeMono11;
                line-height: 1.19rem;

                &::before {
                    content: '';
                    height: toRem(5);
                    width: toRem(5);
                    display: inline-block;
                    background-image: url('../../assets/icons/Bullet.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-right: toRem(5);
                    vertical-align: middle;
                }
            }
        }

        & > .slider-view {
            column-count: 1;
        }

    }
}
