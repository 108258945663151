@import "./src/scss/index";
.icon-button-wrapper {
  .label {
    margin-bottom: 0.5625rem;
  }

  .icon-button {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    color: $themeColor12;

    &:hover{
      color: $themeColor7;
    }
  }
}
