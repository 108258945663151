@import "./src/scss/index";
.half-day-container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: $themeMono11;
    position: relative;

    .half-day {
        width: inherit;
        height: inherit;
        color: $themeMono1;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
            border-right: 1px solid $themeMono1;
        }

        &.selected {
            background-color: $themeColor16;
        }
    }
}
