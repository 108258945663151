@import "./src/scss/index";
.account-companyAttributes {
    @extend .flexCol;

    &_title {
        margin-bottom: toRem(24);
    }
    .row {
        @extend .flexCol;
        min-width: toRem(440);
        max-width: toRem(722);

        &:not(:last-child) {
            margin-bottom: toRem(24);
        }

        .account-attribute {
            line-height: 1;
            margin: 0 0 toRem(9) 0;
        }
        
        .values-container {
            border: 1px solid $themeMono15;
            border-radius: 0.2rem;
            background-color: $themeMono2;
            color: $themeMono15;
            width: fit-content;
            min-width: toRem(414);
            padding: toRem(9);
        }
    }
}
