@import "./src/scss/index";
.repeat-slider {
    height: unset;
    max-height: 100vh;
    min-height: 100vh;
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h1 {
        margin-bottom: 1rem;
    }
    .inlineFields {
        .inputWrapper {
            input,
            &.repeat-start-date {
                margin-left: 1rem;
            }

            &.repeat-end-date {
                flex: 0;
            }

            label {
                text-align: start;
                color: $themeColor7;
                margin-bottom: 0;
                font-weight: normal;
            }
            .MuiInput-root {
                text-align: left;
            }

            .MuiSelect-select {
                font-family: $fontFamily;
                padding-right: 18px;
            }
        }

        .rdt {
            thead {
                tr:first-child {
                    height: 2rem;
                    .rdtSwitch {
                        font-size: 1rem;
                    }
                }
                tr:nth-child(2) {
                    height: 1.5rem;
                }
            }

            td,
            .dow {
                height: 2rem;
                width: 2rem;
                min-width: 2rem;
                height: 2rem;
            }
        }

        .repeat-every {
            flex: 0;
        }
    }

    .inlineFields:not(:first-child) {
        margin-top: 1rem;
    }

    .drop-down {
        width: 7rem;
    }

    .repeat-datePicker {
        min-width: 9rem;
        height: 2rem;
        padding: 1rem;

        &.error {
            border-color: $themeColor21;
            background-color: $themeColor22;
        }
    }

    .repeat-calendar-container {
        @extend .flexCol;
        transition: transform 0.4s ease;

        .divider {
            width: 100%;
            border-bottom: 0.1rem solid $themeColor23;
        }

        .close-calendar {
            align-self: flex-end;
            margin: 0.5rem 0;
            height: 0.7rem;
            width: 0.7rem;
            cursor: pointer;

            &:hover {
                color: $themeColor30;
            }
        }
    }

    .repeat-every-container {
        justify-content: flex-start;

        .every-count {
            align-self: flex-end;
            font-size: 0.875rem;
            margin-left: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }

    .radio-button-selector {
        height: auto;
        padding: 0;
        max-width: 15rem;
        margin-top: 1rem;

        .MuiFormControlLabel-root {
            height: 2rem;
        }

        .radio-button {
            padding-right: 0.25rem;
        }

        label {
            margin-right: 0;
        }

        .MuiFormControlLabel-label {
            font-size: 1rem !important;
            color: $themeMono12;
        }
    }

    .repeat-on-container {
        display: flex;
        @extend .flexCol;
        align-items: flex-start;

        label {
            font-size: 0.875rem;
        }

        .checkboxes {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 0.8rem;

            .checkbox-container {
                position: relative;

                &.error {
                    input[type='checkbox'] + label {
                        border-color: $themeColor21;
                        background-color: $themeColor22;
                    }
                }

                input[type='checkbox'] {
                    appearance: none;
                }

                input[type='checkbox']::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 2rem;
                    height: 2rem;
                    z-index: 2;
                }

                input[type='checkbox'] + label {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    color: $themeMono13;
                    width: 2rem;
                    height: 2rem;
                    bottom: 0;
                    border: 1px solid $themeColor3;
                    border-radius: 0.2rem;
                }

                input[type='checkbox']:checked + label {
                    color: $themeMono1;
                    background-color: $themeColor33;
                    border: 1px solid $themeColor33;
                    border-radius: 0.2rem;
                }

                input[type='checkbox']:disabled + label {
                    background-color: $themeMono3;
                    opacity: 0.3;
                    border-color: $themeMono12;
                }
            }
        }
    }

    small {
        display: flex;
        color: $themeColor6;
        margin-top: 1.25rem;
        text-align: left;
        align-items: center;
        font-size: 0.75rem;
        margin-top: 1.5rem;

        svg {
            height: 2rem;
            width: 2rem;
            margin-right: 0.5rem;
            flex-shrink: 0;
        }
    }

    .edit-message {
        color: gray;
        font-size: 0.75rem;
        text-align: left;
        margin-bottom: 1rem;
    }

    .repeat-button-container {
        @extend .flexRow;
        margin-top: 3.125rem;
        justify-content: space-evenly;

        .delete-button {
            background: $themeColor6;
            padding: 0 1rem;

            &:hover {
                background: $themeColor18;
            }
        }
    }
}
