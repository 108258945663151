@import "./src/scss/index";
.sign-out-container {
    @extend .flexCol;
    position: fixed;
    background: $themeMono1;
    width: toRem(480);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $borderRadiusMedium;
    z-index: 10;

    .sign-out-header {
        @extend .flexCol;
        align-items: center;
        justify-content: center;
        padding: toRem(20) toRem(30);
        background-color: $themeColor12;
        color: $themeMono1;
        border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;

        h1 {
            font-size: toRem(20);
            line-height: toRem(28);
            font-weight: $fontWeight;
            color: $themeMono1;
        }
    }

    .sign-out-content {
        @extend .flexCol;
        align-items: center;
        justify-content: center;
        padding: toRem(12) toRem(30);

        p {
            color: $themeMono13;
            font-size: toRem(14);
            text-align: center;
            margin-bottom: toRem(10);

            a {
                color: $themeColor7;
            }
        }

        .sign-out-button {
            padding: toRem(8) toRem(20);
            background-color: $themeColor5;
            color: $themeMono13;
        }
    }
}
