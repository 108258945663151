@import "./src/scss/index";
.person-search {
  .label {
    margin-bottom: 0.5rem;
  }

  .person-search-panel {
    width: 27.5rem;
    overflow-y: hidden;

    h1 {
      margin-bottom: 1.5rem;
    }

    .content {
      justify-content: start;
    }
  }
}
