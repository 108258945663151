@import "./src/scss/index";
.bookingGridWrap {
    height: 100%;
    overflow: hidden;

    .scheduler_default_main {
        border: 0;
        font-family: $fontFamily;
        height: 100% !important;

        > div:nth-child(1),
        > div:nth-child(2),
        > div:nth-child(3) {
            height: 100% !important;
        }
    }

    .scheduler_default_timeheadergroup_inner {
        border-bottom: 0;
    }

    .scheduler_default_tree_image_expand,
    .scheduler_default_tree_image_collapse {
        pointer-events: none;
    }

    .scheduler_default_timeheadercol_inner,
    .scheduler_default_timeheadergroup_inner,
    .scheduler_default_timeheader_cell {
        border-color: $themeMono1;
    }

    .scheduler_default_timeheader_cell {
        color: $themeMono1;
        background-color: $bodyCopyColor;
    }

    .scheduler_default_timeheadergroup {
        // @todo: Adjusted size to 2rem from 2.5rem until we can make the 00 element of time smaller
        font-size: 2rem;
        font-weight: $fontWeightLight;
    }

    .last_time_header > div {
        border: 0;
    }

    .scheduler_default_corner {
        color: $themeColor3;
        background-color: $themeColor1;
    }

    .scheduler_default_timeheadercol {
        height: 1.875rem !important;
        top: 4.375rem !important;
    }

    .scheduler_default_timeheadercol_inner {
        align-items: flex-start;
        padding-top: 0.3125rem;
    }

    .scheduler_default_timeheadergroup {
        height: 4.375rem !important;
    }

    .scheduler_default_timeheadercol_inner {
        align-items: start;
    }

    .scheduler_default_matrix_horizontal_line {
        background-color: $weakVerticalLine;
    }
    .scheduler_default_matrix_vertical_line {
        display: none;
    }

    .scheduler_default_resourcedivider,
    .scheduler_vertical_divider {
        background-color: $strongVerticalLine;
    }

    .scheduler_default_rowheader {
        cursor: pointer;

        &.areaHeader {
            font-weight: $fontWeightBold;
            font-size: 1rem;
            color: $themeMono1;
            background-color: $themeColor34;

            .scheduler_default_rowheader_inner {
                border-top: none;
            }

            div.scheduler_default_tree_image_collapse {
                top: 50% !important;
                transform: translateY(-25%);
                color: $themeMono1;
            }

            &:hover {
                background-color: $themeColor35;
            }
        }

        &.areaSubHeader {
            font-weight: $fontWeight;
            font-size: 0.875rem;
            color: $themeColor1;
            background-color: $themeColor36;

            .scheduler_default_rowheader_inner {
                border-top: none;
            }

            div.scheduler_default_tree_image_collapse {
                top: 50% !important;
                transform: translateY(-25%);
                color: $themeMono1;
            }

            &:hover {
                background-color: $themeColor37;
            }
        }

        &.resourceHeader {
            color: $themeMono11;
            font-size: 1rem;
            font-weight: $fontWeight;
            white-space: nowrap;
            background-color: $themeMono2;

            .scheduler_default_rowheader_inner {
                margin-right: 0.4375rem;
                border-right: none;
                overflow: hidden;
            }

            &:hover {
                background-color: $themeMono1 !important;
            }
        }

        .scheduler_default_rowheader_inner {
            .scheduler_default_rowheader_inner_indent {
                margin-left: 0 !important;
            }
        }

        .icon {
            transform: translate(-1rem, toRem(-7));
            background-position: 50% 50%;
            background-repeat: no-repeat;
            border-radius: 50%;
            display: block;
            width: 100%;
            height: 100%;

            &audioConferencing {
                background-image: url('../../assets/icons/deskInfoGrid/AudioConferencing.svg');
            }

            &_networking {
                background-image: url('../../assets/icons/deskInfoGrid/Networking.svg');
            }

            &_other {
                background-image: url('../../assets/icons/deskInfoGrid/Other.svg');
            }

            &_pc {
                background-image: url('../../assets/icons/deskInfoGrid/PC.svg');
            }

            &_restricted {
                background-image: url('../../assets/icons/deskInfoGrid/Restricted.svg');
            }

            &_restrictedTo {
                background-image: url('../../assets/icons/deskInfoGrid/RestrictedTo.svg');
            }

            &_settings {
                background-image: url('../../assets/icons/deskInfoGrid/Settings.svg');
            }

            &_telephone {
                background-image: url('../../assets/icons/deskInfoGrid/Telephone.svg');
            }

            &_videoConferencing {
                background-image: url('../../assets/icons/deskInfoGrid/VideoConferencing.svg');
            }

            &_wheelchair {
                background-image: url('../../assets/icons/deskInfoGrid/Wheelchair.svg');
            }

            &_info {
                background-image: url('../../assets/icons/deskInfo/InformationColor.svg');
            }
        }
    }

    .scheduler_default_crosshair_top {
        background-color: $themeMono1;
    }

    .scheduler_default_timeheadercol .scheduler_default_timeheader_float {
        align-items: flex-start;
    }

    div.scheduler_default_rowheader_scroll > div > div:nth-child(1) > div {
        display: none;
    }
}

.unavailable,
.restricted {
    // TODO: Place this appropriately in the scss tree
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(
        135deg,
        #e8e8e8 10%,
        #f5f5f5 10%,
        #f5f5f5 50%,
        #e8e8e8 50%,
        #e8e8e8 60%,
        #f5f5f5 60%,
        #f5f5f5
    );
    background-size: 6px 6px;

    :hover {
        background-color: white;
    }
}

.setup-cleardown {
    // TODO: Place this appropriately in the scss tree
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(
        135deg,
        #74a9c5 10%,
        #f5f5f5 10%,
        #f5f5f5 50%,
        #74a9c5 50%,
        #74a9c5 60%,
        #f5f5f5 60%,
        #f5f5f5
    );
    background-size: 6px 6px;

    :hover {
        background-color: white;
    }
}

.checked-in-cells {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(
        135deg,
        #74a9c5 10%,
        transparent 10%,
        transparent 50%,
        #74a9c5 50%,
        #74a9c5 60%,
        transparent 60%,
        transparent
    );
    background-size: 6px 6px;
}

.setup-cleardown-intersect {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(
        135deg,
        #74a9c5 10%,
        #9bb9c9 10%,
        #9bb9c9 50%,
        #74a9c5 50%,
        #74a9c5 60%,
        #9bb9c9 60%,
        #9bb9c9
    );
    background-size: 6px 6px;
}

.not-working-hours {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $themeMono2;
}

.scheduler_default_cell.scheduler_default_cell_business {
    &:hover {
        background-color: $themeColor4;
    }

    &:not(.scheduler_default_cellparent) {
        border: 1px $themeMono4;
        border-style: none solid none solid;
    }

    &.scheduler_default_cellparent {
        background-color: $themeColor34;
    }

    &.subHeaderCell {
        background-color: $themeColor36;
    }
}

.scheduler_default_event {
    box-sizing: border-box !important;

    .scheduler_default_event_inner,
    .scheduler_default_event_float_inner {
        background: $themeColor13;
        border: 0;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 0.7rem;
        overflow-x: hidden;
        padding: 0.62rem 0.31rem 0.31rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        h2 {
            font-size: inherit;
            margin: 0;
        }
    }

    .scheduler_default_event_bar_inner,
    .scheduler_default_event_bar {
        background-color: $themeColor2;
        height: 0.3125rem;
        left: 0%;
        width: 100%;
    }
}

.scheduler_default_crosshair_left {
    background-color: $themeMono4 !important;
    opacity: 0.4 !important;
}

.update-buttons {
    height: 100%;

    .alignRight {
        display: flex;
        flex-direction: column;

        .button {
            width: 100%;
            margin-bottom: 1rem;

            &.secondary {
                background-color: $themeColor5;
                color: $themeMono13;
            }

            &.primary {
                background-color: $themeColor16;
                color: $themeMono1;
            }
        }
    }
}
