@import "./src/scss/index";
.actionButtons {
    @extend .flexRow;
    align-items: center;
    width: 100%;
    height: toRem(70);
    padding: 0 toRem(25);
    justify-content: space-between;

    .alignLeft {
        @extend .flexRow;
        align-items: flex-start;
    }

    .alignRight {
        @extend .flexRow;
        align-items: flex-end;
    }

    button {
        margin: 0 toRem(5);
        min-height: toRem(40);
        min-width: toRem(130);

        svg {
            height: 0.75rem;
            margin-right: 0.5rem;
        }
    }
}
