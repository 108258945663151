@import "./src/scss/index";
.booking__datePicker {
    align-items: center;
    display: flex;
    font-family: $fontFamily;
    height: 100%;
    justify-content: flex-start;
    padding: 0 1rem;
    min-width: toRem(296);

    .iconOnly {
        color: $themeMono1;
        align-self: center;
        padding: 0;

        &.chevron + .calendar {
            margin-left: 1rem;
        }

        &.calendar {
            width: 1.5rem;
            height: 1.5rem;

            & + .calendar {
                margin-left: 0.5rem;
            }
        }

        & + .dateWrapper {
            margin-left: 1rem;

            & + .iconOnly {
                margin-left: 1rem;
            }
        }

        &:hover {
            color: $themeColor3;
        }

        &:active {
            outline: none;
        }
    }

    .dateWrapper {
        color: $themeColor16;
        display: flex;
        flex-direction: column;
        font-size: 2rem;
        font-weight: $fontWeightLight;
        height: 100%;
        justify-content: center;
        max-width: 75%;
        text-transform: uppercase;

        .dayName,
        .timezone {
            color: $themeMono1;
            font-weight: 400;
            margin-left: 1.875rem;
        }

        .dayName {
            font-size: 0.875rem;
            letter-spacing: 0.05rem;
        }

        .month-n-btns-row {
            font-size: 2.125rem;
            line-height: 2.125rem;
            display: flex;
            .month {
                white-space: nowrap;
                margin: 0 1rem;
            }
            .chevron {
                stroke-width: 12%;
                width: 0.625rem;
                height: 1.125rem;
            }
        }

        .timezone {
            font-size: 0.625rem;
        }
    }

    &--floorplan {
        background: $themeColor7;

        .dateWrapper {
            color: $themeMono1;
        }
    }
}
