@import "./src/scss/index";
.backgroundMask {
    z-index: 6;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;

    &.open {
        opacity: 0.5;
        width: 100vw;
        height: 100vh;
        background: $themeMono13;
    }

    &.light {
        opacity: 1;
        background: $backgroundMask;
    }
}
