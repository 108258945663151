@import "./src/scss/index";
.tabsContainer {
    @extend .flexCol;
    overflow: hidden;
    height: calc(100% - #{$footerHeight});

    .tabs {
        @extend .flexRow;
        background: $themeColor13;
        padding: 0 2rem;
        position: relative;

        .right-side-element {
            align-self: center;
            margin-left: auto;
        }

        .private-booking-marker {
            width: 126px;
            height: 24px;
            font-size: 0.8125rem;
            background-color: $themeColor1;
            position: absolute;
            right: 30px;
            bottom: 10px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: $themeColor1;
            }

            .private-booking-text {
                margin-left: 4px;
                color: $themeMono1;
            }
        }

        .tab {
            padding: 0.875rem 0.5rem 0.5rem;
            border-radius: 0;
            width: 7.5rem;
            font-size: 0.875rem;
            transition: background 0.2s;
            background: $themeColor12;
            color: $themeMono1;
            outline: none;

            & + .tab {
                margin-left: 0.125rem;
            }

            &:nth-of-type(1) {
                border-top-left-radius: 1rem;
            }

            &:last-of-type {
                border-top-right-radius: 1rem;
            }

            &:hover {
                background: $themeColor16;
            }

            svg {
                height: 2rem;
                width: 2rem;
                margin: 0 auto 0.25rem;
                color: $themeMono1;
                fill: $themeMono1;
                stroke-width: 3%;
            }

            &.selected {
                background: $themeMono1;
                color: $themeMono12;

                svg {
                    color: $themeColor12;
                    fill: $themeColor12;
                }

                .number {
                    color: $themeColor7;
                }
            }
        }
    }

    .banner {
        width: 100%;
        background-color: $themeColor39;
        font-size: 14px;
        color: $themeMono13;
        padding: 10px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .repeat-banner-button {
            background-color: $themeMono1;
            height: 1.5rem;
            border: 1px solid $themeColor12;

            p {
                font-size: 1rem;
                color: $themeColor12;
                text-transform: lowercase;
                padding: 0.5rem 0;

                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }

    .tabContent {
        @extend .flexRow;
        padding: 1.5rem 1.875rem;
        overflow-y: auto;
        height: 100%;

        &.selected {
            display: flex;
        }
    }
}
