@import "./src/scss/index";
.timeline-container {
    .arrow-container {
        width: 15px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $themeMono1;
        transition: 0.3s;

        .arrow {
            width: 0;
            height: 0;
        }
    }

    .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--arrow-right {
        background-color: $themeMono1;

        .right-container {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            margin-left: 5px;
            background-color: $themeColor14;

            .right {
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 4px solid $themeColor7;
            }

            &:hover {
                background-color: $themeColor7;

                .right {
                    border-left: 4px solid $themeMono1;
                }
            }
        }
    }

    .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--arrow-left {
        background-color: $themeMono1;

        .left-container {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            margin-right: 5px;
            background-color: $themeColor14;

            .left {
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-right: 4px solid $themeColor7;
            }

            &:hover {
                background-color: $themeColor7;

                .left {
                    border-right: 4px solid $themeMono1;
                }
            }
        }
    }

    .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--scroll-container {
        background-color: $themeMono1;
        height: 30px;
    }

    .timeline-item {
        background-color: $themeMono1;
        height: 30px;
        position: relative;

        &-line {
            background-color: $themeColor31;
            height: 10px;
            width: 44px;
            margin-right: 2px;

            &--available {
                background-color: $themeColor31;
            }

            &--my-booking {
                background-color: $themeColor16;
            }

            &--setup-clear-down {
                background-color: $themeColor23;
            }

            &--is-private {
                background-color: $themeMono13;
            }

            &--other-user-booking {
                background-color: $themeColor6;
            }

            &--restricted {
                background-color: $themeColor23;
            }

            &--unavailable_COVID {
                background-color: $themeColor19;
            }

            &--unavailable {
                background-color: $themeColor23;
            }
        }

        .first-item-radius {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .last-item-radius {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-right: 0;
        }

        &-hours {
            position: absolute;
            width: 44px;
            font-size: 11px;
            color: $themeMono13;
        }

        .two-numbers-hour {
            right: 10px;
            z-index: 1;
        }

        .one-number-hour {
            right: 6px;
            z-index: 1;
        }

        .last-item-hour {
            top: 10px;
            right: 0px;
            width: fit-content;
            z-index: 1;
        }

        .first-item-hour {
            top: 10px;
            left: 0px;
            z-index: 1;
        }
    }
}
