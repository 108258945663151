@import "./src/scss/index";
.report-filter-panel {
  width: 27.5rem;
  padding-bottom: 0;
  overflow-y: hidden;

  h1 {
    margin-bottom: 0.5rem;
  }

  .content {
    width: 100%;
  }

  .report-filter {
    &-container {
      @extend .flexCol;
      align-items: center;
      height: 100%;
      width: 100%;

      .toggle-btn {
        align-self: center;
        margin-bottom: 1rem;
        margin-top: 1rem;
        border-radius: 1rem;
        background-color: $themeMono1;
        border-color: $themeColor2;
        color: $themeColor2;
        text-transform: none;
        height: 2rem;
        width: 11rem;
        font-size: 14px;

        &:not(:disabled) {
          &:hover {
            color: $themeMono1;
            background-color: darken($themeColor2, 10%);
            cursor: pointer;
          }
        }
      }
    }

    &-content-scroll {
      height: calc(100% - 9.5rem);
      overflow-y: auto;
      flex-direction: column;
      width: 100%;
    }

    &-actions {
      position: fixed;
      display: flex;
      justify-content: center;
      width: 100%;
      border-top: 1px solid $themeMono6;
      bottom: 0;
      z-index: 5;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}
