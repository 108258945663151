/* Colours */
$themeColor1: #0f2b4a;
$themeColor2: #1996c7;
$themeColor3: #42bfcf;
$themeColor4: #ebf5fa;
$themeColor5: #f0bf00;
$themeColor6: #fd6151;
$themeColor7: #006496;
$themeColor8: #424148;
$themeColor9: #535358;
$themeColor10: #15a6d0;
$themeColor11: #afafb0;
$themeColor12: #1796c6;
$themeColor13: #006496;
$themeColor14: #e6f3f9;
$themeColor15: #ff6601;
$themeColor16: #30c4f0;
$themeColor17: #f8f9fa;
$themeColor18: #cc3300;
$themeColor19: #fc0;
$themeColor20: #ccedfc;
$themeColor21: #ff0000;
$themeColor22: #fdf3f3;
$themeColor23: #828285;
$themeColor24: #313036;
$themeColor25: #414045;
$themeColor26: #156a8b;
$themeColor27: #eaf9fe;
$themeColor28: #199ed8;
$themeColor29: #145d83;
$themeColor30: #103c5d;
$themeColor31: #66cc33;
$themeColor32: #53a52a;
$themeColor33: #144a72;
$themeColor34: #578fa6;
$themeColor35: #89b0c0;
$themeColor36: #cbdde6;
$themeColor37: #dae7ed;
$themeColor38: #ebf5fa;
$themeColor39: #f9e599;
$themeColor40: #e62db2;

$themeMono1: #fff;
$themeMono2: #f2f2f2;
$themeMono3: #d7d7d7;
$themeMono4: #e4e4e4;
$themeMono5: #dbdbdb;
$themeMono6: #ccc;
$themeMono7: #c9c9c9;
$themeMono8: #c0c0c0;
$themeMono9: #bbb;
$themeMono10: #999999;
$themeMono11: #666;
$themeMono12: #333;
$themeMono13: #000;
$themeMono14: #aeaeae;
$themeMono15: #aaaaaa;
$themeMono16: #f9f9f9;
$themeMono17: #666666;
$themeMono18: #f8f8f8;
$themeMono19: #333333;
$themeMono20: #b7b7b7;

$theme-shadow-1: rgba($themeMono13, 0.75);

$positive: #2cc478;
$neutral: #f90;
$negative: $themeColor6;
$info: $themeColor28;

// Copy
$bodyCopyColor: $themeColor7;
$headerCopyColor: $themeMono11;

/* Main Navigation */
$navBackground: $themeColor24;
$navLinkBackground: $themeColor25;
$navLinkBackgroundActive: $themeColor16;
$navColor: $themeMono14;
$navLinkBackgroundHover: $themeColor23;

/* Form Elements */
$borderRadius: 0.2rem;
$borderRadiusMedium: 0.625rem;

$inputBorder: solid 0.0625rem $themeColor3;
$inputBorderHover: $themeColor13;
$inputBorderDisabled: $themeMono3;
$inputBackgroundDisabled: $themeMono2;
$inputBorderFocus: $themeColor13;
$inputColorDisabled: $themeMono10;
$inputText: $themeColor4;

$error: $themeColor6;
$inputTextColor: $themeMono12;
$labelTextColor: $bodyCopyColor;

$actionButtonsBackground: $themeMono2;
$actionButtonsBorder: $themeMono4;
$actionButtonPanelHeight: 4.575rem;

/* Header and Footer */
$siteHeaderBackground: $themeColor1;
$siteHeaderBoxShadow: $theme-shadow-1;
$siteFooterBackground: $themeMono8;
$headerHeight: 4.625rem;
$footerHeight: 4.625rem;

$userProfileMenuBackground: $themeMono5;

$bookADeskHeaderHeight: 5rem;
$bookingHeaderHeight: 5.625rem;

/* DayPilot */
$resourceParentRowBGColor: $themeMono3;

/* DayPilot Icons */
$settingBGColor: $themeColor15;
$strongVerticalLine: $themeMono7;
$weakVerticalLine: $actionButtonsBorder;

/* Calendar Control */
$oldDayColor: $themeMono6;
$todayColor: $bodyCopyColor;
$dayHoverColor: $themeColor13;
$dayActiveColor: $themeColor12;

/* Time Picker */
$timePickerBorderHover: $dayHoverColor;
$timePickerBackgroundHover: $themeColor4;

// Home
$welcomeBackground: rgba($themeColor1, 0.55);
$alertBackground: rgba($themeColor1, 0.8);

// Tabs
$tabContentPadding: 1.5rem 1.875rem;

// Shadows and Masks
$dialogShadow: 2px 2px 12px rgba(0, 0, 0, 0.35);
$backgroundMask: rgba(255, 255, 255, 0.9);
$profileShadow: 0 0 8px rgba(0, 0, 0, 0.2);
