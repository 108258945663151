@import "./src/scss/index";
.visitor-panel {
    .visitor-create-edit-panel {
        width: 27.5rem;
        padding-bottom: 2.5rem;

        .content {
            display: flex;
            width: 22.5rem;
            height: auto;

            .new-visitor-form {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding-bottom: 0;

                .visitor-booking-form-wrapper {
                    .horizontal {
                        justify-content: space-between;
                    }
                }

                .input-fields {
                    margin-bottom: 3.125rem;
                }

                .title {
                    .select__control {
                        height: 2.25rem;
                        min-height: 2.25rem;
                    }

                    .select__menu {
                        width: 14rem !important;
                        text-align: start;
                        margin-top: 0rem;
                        z-index: 2;
                    }
                }

                .buttons {
                    display: flex;
                    justify-content: center;
                    margin-top: auto;

                    button + button {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}
