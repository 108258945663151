@import "./src/scss/index";
.chip {
    @extend .flexRow;
    align-items: center;
    background: $themeColor28;
    height: 1.375rem;
    border-radius: 0.6875rem;
    margin: 0 0.3125rem;
    padding: 0.25rem;
    
    p {
        color: $themeMono1;
        font-size: 0.75rem;
        margin: 0 0.5rem;
    }

    .closeIcon {
        margin-left: 0.125rem;
        height: 0.875rem;
        width: 0.875rem;
        background: url(../../assets/icons/CloseRoundelOff.svg) no-repeat;

        &:hover {
            background: url(../../assets/icons/CloseRoundelOver.svg);
        }
    }
}