@import "./src/scss/index";
.page-home {
    .welcomeMessage {
        @extend .flexRow;
        align-items: flex-end;
        font-size: 2.125rem;
        font-weight: $fontWeightLight;
        color: $themeMono1;
        padding: toRem(27) toRem(24) toRem(14) toRem(24);
        background: $welcomeBackground;

        h1 {
            flex: 1;
        }

        .welcomeTo {
            @extend .flexCol;
            align-items: flex-end;
            font-weight: $fontWeightLight;

            span {
                font-size: 1.25rem;
                line-height: 1.25rem;
            }

            svg {
                width: 13rem;
                height: 2.5rem;
            }
        }
    }
}
