@import "./src/scss/index";
.errorMessage {
    @extend .flexCol;
    position: fixed;
    background: $themeMono1;
    width: 35%;
    padding: 2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    h1 {
        font-weight: 700;
        font-size: 2rem;
    }

    button.close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: transparent;
        color: grey;
        padding: 0;
        margin: 0;
    }
}
