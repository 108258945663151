@import "./src/scss/index";
.viewsPanelButton {
    @extend .flexRow;
    justify-content: center;

    width: toRem(32);
    height: toRem(27);
    border-radius: toRem(2);
    margin-right: toRem(4);
    background-color: $themeColor29;

    svg {
        height: toRem(18);
        margin-top: toRem(3);
        color: $themeMono1;
    }

    &:hover {
        background-color: $themeColor16;
        cursor: pointer;

        svg {
            color: $themeColor30;
        }
    }

    &.active {
        background-color: $themeMono1;

        svg {
            color: $themeColor30;
        }
    }

    &.disabled {
        background-color: $themeMono3;
        cursor: initial;

        svg {
            color: $themeMono15;
        }
    }
}
