@import "./src/scss/index";
.reports-page {
  position: relative;

  .type-n-date {
    padding: 1.5rem 2rem;
    display: flex;

    .dashboardurl{
      width: 200rem;
    }
  }

  .report-filters{
    display: flex;
    flex-direction: column;
    background-color: $themeMono16;
    height: 100%;
  }

  .actionButtonsPanel{
    background-color: $themeMono2;
  }
}
