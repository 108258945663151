@import "./src/scss/index";
.report-filter-content-scroll {
  .option {
    &:first-child {
      border-top: 1px solid $themeMono6;
    }

    .optionName {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
    }
  }
}
