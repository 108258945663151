@import "./src/scss/index";
.multiSelect-container {
    width: 100%;
    margin: toRem(9) 0 0 0;
    position: relative;
    &--has-value {
        .multiSelect__control {
            background: $themeColor4 !important;
        }
        border-color: $themeColor21;
    }

    .multiSelect__control {
        border-color: $themeColor21;
        border-radius: $borderRadius;
        color: $themeMono12;
        font-size: toRem(15);
        border: $inputBorder;
        background: $themeMono1;

        &.error {
            border-color: $themeColor21;
            background-color: $themeColor22 !important;

            &:focus {
                outline: 0;
            }

            &:focus-within {
                outline: initial;
            }
        }
        
        &:hover,
        &--is-focused {
            background: $themeColor20 !important;
            border-color: $inputBorderHover !important;
            box-shadow: none;
            .multiSelect__indicators {
                .multiSelect__dropdown-indicator {
                    color: $themeColor1;
                }
            }
        }
        &--is-disabled {
            background: $inputBackgroundDisabled !important;
            border-color: $inputBorderDisabled;
            color: $inputColorDisabled !important;
            .multiSelect__placeholder,
            .multiSelect__indicators {
                display: none;
            }
        }

        .multiSelect__value-container {
            border-radius: $borderRadius;
            padding: 0 0.75rem;

            > div {
                padding: 0;
            }
        }

        .multiSelect__indicators {
            border-radius: $borderRadius;
            background: transparent;
            height: 100%;
            .multiSelect__indicator-separator {
                display: none;
            }

            .multiSelect__dropdown-indicator {
                color: $themeColor2;
            }
        }

        &--is-error {
            border-color: $themeColor21;
            background-color: $themeColor22 !important;

            &:focus {
                outline: 0;
            }

            &:focus-within {
                outline: initial;
            }
        }
    }

    .multiSelect__menu {
        color: $themeMono12;
        font-size: toRem(14);
        font-weight: $fontWeightLight;

        .multiSelect__option {
            min-height: toRem(40);
            &--is-selected {
                background-color: $themeColor4;
                font-weight: $fontWeightBold;
                color: $themeMono12;
            }
            &--is-focused {
                background-color: $themeColor13;
                font-weight: $fontWeight;
                color: $themeMono1;
            }
        }
    }
}
