@import "./src/scss/index";
.selected-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5rem;

  .selected-item-label {
    display: flex;
    align-items: center;
    color: $themeColor24;
    max-width: 19.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .trash-icon-button {
    height: 1rem;
    width: 1rem;
    margin-left: 1.625rem;
  }
}
