@import "./src/scss/index";
.toggle {
    position: relative;
    color: $themeMono11;
    font-size: 0.8rem;
    font-weight: $fontWeightBold;

    input {
        display: none;

        &:checked + label:before {
            background-color: $positive;
        }
        &:checked + label:after {
            left: 1.9375rem;
        }

        &:disabled + label {
            &:after {
                background-color: $themeMono20;
            }

            &:hover {
                cursor: unset;
            }
        }
    }

    label {
        display: flex;
        flex-direction: column-reverse;

        &:hover {
            cursor: pointer;
        }

        &:before,
        &:after {
            content: '';
        }

        &:before {
            background-color: $themeMono10;
            border: solid 1px transparent;
            border-radius: 1rem;
            margin-top: 0.625rem;
            height: 2rem;
            width: 3.75rem;
            will-change: background-color;
            transition: background-color 0.1s linear;
        }

        &:after {
            background-color: $themeMono1;
            border-radius: 50%;
            height: 1.625rem;
            left: 0.1875rem;
            position: absolute;
            bottom: 0.1875rem;
            width: 1.625rem;
            will-change: left;
            transition: left 0.1s linear;
        }
    }
}
