@import "./src/scss/index";
.TextBoxElement {
    position: relative;

    input {
        height: 2.5rem;
        line-height: 1;
    }

    textarea {
        resize: none;
        padding: 0.4rem 0.4rem 0.4rem 0.75rem;
        width: 100%;
        line-height: 1.5rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    input,
    textarea {
        border-radius: $borderRadius;
        border: $inputBorder;
        color: $themeMono12;
        font-size: toRem(15);
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        width: 100%;

        &:not(.error) {
            &:disabled {
                background-color: $inputBackgroundDisabled;
                border-color: $inputBorderDisabled;
                color: $inputColorDisabled;

                &[value] {
                    background-color: $inputBackgroundDisabled;
                    border-color: $inputBorderDisabled;
                }
            }

            &:hover {
                border-color: $inputBorderHover;
            }

            &.hasValue {
                background-color: $inputText;
                border-color: $inputBorder;
                &:not(:disabled) {
                    &:hover {
                        background-color: $themeColor20;
                    }
                }
            }

            &:focus {
                outline: 0;
                border-color: $inputBorderFocus;
                background-color: $inputText;
            }

            &:focus-within {
                outline: initial;
            }
        }

        &.error {
            border-color: $themeColor21;
            background-color: $themeColor22 !important;

            &:focus {
                outline: 0;
            }

            &:focus-within {
                outline: initial;
            }
        }
    }

    button + input {
        margin-top: 0.5625rem;
    }

    button {
        position: absolute;
        right: 0;
        font-size: 0.7rem;
        font-weight: $fontWeightBold;
        color: $themeMono10;
    }
}
