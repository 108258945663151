@import "./src/scss/index";
.search-result-item {
  height: 3.375rem;
  padding-left: 0.75rem;
  padding-right: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $themeMono17;

  &:nth-child(2n+1) {
    background-color: $themeMono18;
  }

  &:hover {
    background-color: $themeColor4;
    cursor: pointer;
  }

  .search-item-info {
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      max-width: 20rem;
  }

  &.selected {
    background-color: $themeColor7;

    &:hover {
      background-color: $themeColor7;
    }
  }
}
