@import "./src/scss/index";
.edit-columns-form {
  @extend .flexCol;
  align-items: center;
  height: 100%;

  .toggle-btn {
    margin-bottom: 2rem;
    border-radius: 1rem;
    background-color: $themeMono1;
    border-color: $themeColor2;
    color: $themeColor2;
    text-transform: none;
    height: 2rem;
    width: 11rem;
    font-size: 14px;

    &:not(:disabled) {
      &:hover {
        color: $themeMono1;
      }
    }
  }

  .columns-container {
    height: calc(100% - 5.5rem);
    overflow-y: auto;
    flex-direction: column;
    width: 100%;

    .column-item {

      .option {
        @extend .flexRow;
        align-items: center;
        height: 3.125rem;
        border-top: 1px solid $themeMono6;
        padding-left: 0.875rem;

        .optionName {
          font-size: 0.875rem;
          color: $themeMono11;
          margin-left: 0.625rem;

          &.active {
            font-weight: bold;
          }
        }
      }

      input[type='checkbox'] {
        position: relative;
        width: 0.75rem;
        height: 0.75rem;
        appearance: none;
        outline: 1px solid $themeMono6;
        outline-offset: 0.125rem;
        border-radius: 0.125rem;
        cursor: pointer;

        &:hover {
          outline-color: $themeColor12;
        }

        &:checked {
          outline-color: $themeColor12;
          background-color: $themeColor12;
        }
      }
    }

  }


  .btn-container {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid $themeMono6;
    bottom: 0;
    z-index: 5;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

}
