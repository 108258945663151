@import "./src/scss/index";
.requiredLabel {
    color: $themeColor21;
    display: inline-block;
    margin-right: 0.25rem;
}

.errorText {
    @extend .flexRow;
    width: 100%;
    justify-content: flex-end;
    font-size: toRem(12);
    margin-top: toRem(5);
    color: $themeColor21;
}
