@import "./src/scss/index";
.reception-bookings {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .bookings-list{
    height: calc(100% - 1.75rem);
    padding-top: 1.875rem;
    overflow-y: auto;

    .no-items-message{
      padding-top: 5.875rem;
      font-size: 1.5rem;
      font-weight: $fontWeightLight;
      color: $themeMono19;
      text-align: center;
    }
  }
}
