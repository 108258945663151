@import "./src/scss/index";
.repeat-info-page {
    .data-table {
        .repeat-info-icon {
            width: 16px;
            height: 16px;
        }

        .data-grid-component {
            background-color: $themeMono1;
        }

        .MuiDataGrid-row {
            &:last-child {
                border-bottom: 1px solid $themeMono6;
            }
        }

        .selectedRow {
            position: relative;
            color: $themeColor7;

            &::after {
                content: '';
                position: absolute;
                border-top: 0.4rem solid transparent;
                border-bottom: 0.4rem solid transparent;
                border-left: 0.4rem solid $themeColor7;
                left: 0.1rem;
                top: calc(50% - 0.4rem);
            }
        }
    }
}
