@import "./src/scss/index";
.data-table-pagination {
  display: flex;
  align-items: center;

  button{
    height: 1.5rem;
    width: 1.75rem;
    padding: 0;
    color: $themeMono15;
    margin: 0 0.25rem;

    &:hover {
      color: $themeColor12;
    }
    &.Mui-disabled{
      color: $themeMono3;
    }

  }
  .result-per-page{
    display: flex;

    .label{
      font-size: 0.875rem;
      color: $themeColor8;
      overflow: hidden;
      white-space: nowrap;
      align-self: flex-end;
    }
    .per-page{
      margin-left: 1rem;
      .select-container {
        margin: 0;
      }
    }
  }

  .page-navigation{
    display: flex;
    margin-left: 2.5rem;
    white-space: nowrap;
    color: $themeColor24;

    .page-input{
      border: $inputBorder;
      height: 1.5rem;
      width: 2rem;
      border-radius: $borderRadius;
      margin: 0 0.5rem;
      text-align: center;

      &:focus {
        outline: 0;
        border-color: $inputBorderFocus;
        background-color: $inputText;
      }
    }

    .total{
      margin-right: 0.5rem;
    }
  }
  .result{
    color: $themeColor24;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    white-space: nowrap;
  }
}
