@import "./src/scss/index";
.newSlidingPanel {
    @extend .flexCol;
    background-color: $themeMono1;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    width: 25.625rem;
    will-change: transform;
    z-index: 7;
    overflow-x: hidden;

    &.open {
        transform: translateX(0);
    }

    .topActions {
        display: flex;
        justify-content: flex-start;
        padding: toRem(15);

        .close-button {
            height: toRem(18);
            width: toRem(18);
            color: $themeMono19;
        }
    }

    .content {
        flex: 1 1 auto;
        overflow-x: auto;
        height: 100%;
        width: 100%;
    }

    .bottomActions {
        height: toRem(70);
        background-color: $themeMono2;
        border-top: 1px solid $themeMono6;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
