@import "./src/scss/index";
.myBookingsPage {
    .cell {
        &.recurringIcon {
            display: flex;
            justify-content: center;
            padding: 0;
        }

        svg {
            &.recurring-icon {
                width: 1rem;
                height: 1rem;
            }
        }
    }
    .noBookingsMessage {
        color: $themeMono13;
        font-size: 1.125rem;
        margin-left: 1.875rem;
    }

    .data-table {
        padding: 40px 30px;

        .recurring-icon {
            width: 16px;
            height: 16px;
        }

        .data-grid-component {
            background-color: $themeMono1;
        }

        .MuiDataGrid-row {
            cursor: pointer;

            &:hover {
                background-color: $themeColor38 !important;
            }

            &:last-child {
                border-bottom: 1px solid $themeMono6;
            }
        }
    }
}
