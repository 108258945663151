@import "./src/scss/index";
.search-list-item {
    height: 100%;
    padding: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &:nth-child(2n + 1) {
        background-color: $themeMono18;
    }

    &:hover {
        background-color: $themeColor4;
        cursor: pointer;
    }

    .text-wrapper {
        color: $themeMono17;
        min-width: 0;

        .title,
        .text {
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
            text-align: left;
        }

        .title {
            font-size: 0.875rem;
            font-weight: $fontWeightBold;
        }

        .text {
            font-size: 0.8125rem;
        }
    }

    .icon {
        min-height: 1.25rem;
        min-width: 1.25rem;
        color: $themeColor7;
    }

    &.selected {
        background-color: $themeColor7;

        &:hover {
            background-color: $themeColor7;
        }

        .text-wrapper,
        .icon {
            color: $themeMono1;
        }
    }
}
