@import "./src/scss/index";
.tab-btn-with-label-n-number {
   .label {
      font-size: 0.8125rem;
      white-space: nowrap;
   }
   .number {
      font-size: 2.125rem;
      font-weight: $fontWeightLight;
      height: 1.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
