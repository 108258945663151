@import "./src/scss/index";
.multi-day-time-panel.slidingPanel {
    display: flex;
    width: 27.5rem;
    padding: 3.125rem 0 0 0;
    overflow: hidden;

    .content {
        width: 100%;
        color: $themeMono19;
        height: calc(100% - 30rem);
        display: flex;

        .header-labels {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: $fontWeightBold;
            font-size: 0.875rem;
            padding: 0 5.75rem 0.5rem 2.5rem;
        }

        .due-times {
            display: flex;
            flex-direction: column;
            margin: 0 2.5rem 1rem;
            height: calc(100% - 4.5rem);
            overflow-y: auto;
            border-top: 1px solid $themeMono6;

            .date-time-slots {
                display: flex;
                flex-direction: column;
                width: 100%;
                border-bottom: 1px solid $themeMono6;

                .date-time-slots-row {
                    display: flex;
                    width: 100%;
                    height: 3.375rem;
                    min-height: 3.375rem;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;

                    &:nth-child(2n + 1) {
                        background-color: $themeMono18;
                    }

                    .time-slots {
                        width: 6rem;

                        &.isExtended {
                            width: 7.5rem;
                        }

                        .multi-day-time-dropdown {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .action-buttons {
            display: flex;
            justify-content: space-around;
            background-color: $themeMono18;
            padding: 1.125rem 0;
            border-top: 1px solid $themeMono6;
        }
    }
}
