@import "./src/scss/index";
.person-search-form-booking {
    height: 100%;
    display: flex;
    flex-direction: column;

    .search-fields {
        .radio-button-selector {
            height: 2rem;
            padding: 0 0 1rem;

            .radio-button {
                padding-right: 0.25rem;
            }

            .MuiFormControlLabel-label {
                font-size: 0.75rem !important;
                color: $themeMono12;
            }
        }
    }
    .company {
        margin-top: 0.75rem;
    }
}
