@import "./src/scss/index";
.bookingHeader {
    @extend .flexRow;
    min-height: $bookingHeaderHeight;
    max-height: $bookingHeaderHeight;
    background: linear-gradient(to right, $themeColor2, $themeColor26);
    box-shadow: $dialogShadow;
    padding-right: 1.25rem;

    .headerDatePicker,
    .headerTitle {
        width: 18.5rem;
    }

    .headerTitle {
        font-size: 2.5rem;
        padding: 1.25rem 0 1.25rem 1.5rem;
        color: $themeMono1;
        font-weight: $fontWeightLight;
    }

    .resourcesFilter {
        @extend .flexRow;
        align-items: flex-end;
        padding: 1.25rem 0 1.5rem 2.125rem;

        .filter {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            margin-right: 2.5rem;

            p {
                font-size: 0.875rem;
                color: $themeMono1;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            .toggle {
                @extend .flexRow;
                font-weight: $fontWeight;
                margin-right: 0.75rem;
                color: $themeMono3;

                input:checked + label:before {
                    background-color: $positive;
                }

                input:checked + label:after {
                    left: 1.45rem;
                }

                label {
                    &::before {
                        background-color: $themeMono3;
                        margin-top: 0;
                        width: 3.125rem;
                        height: 1.75rem;
                    }

                    &::after {
                        height: 1.562rem;
                        width: 1.562rem;
                        bottom: 0.125rem;
                        left: 0.125rem;
                    }
                }
            }
        }
    }

    .filterContainer {
        @extend .flexRow;
        padding: 1.25rem 0;
        margin-left: auto;
        align-items: flex-end;

        .viewTypes {
            padding: 0 1rem;
        }

        .areas {
            width: 9.5rem;

            label {
                text-transform: uppercase;
                color: $themeMono1;
                font-size: toRem(11);
            }
        }

        .filterView {
            @extend .flexRow;
            height: 100%;
            align-items: flex-end;

            span {
                width: 5rem;
            }
        }
    }

    .select-container {
        width: 100%;
        max-height: 1.5rem;

        .select__control {
            min-height: 1.5rem;
            max-height: 1.5rem;
            font-size: 0.75rem;

            .select__value-container {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                div {
                    max-width: calc(100% - 35px);
                }

                div:last-child {
                    position: absolute;
                    top: -0.5rem;
                    opacity: 0;
                }
            }

            .select__indicators {
                position: absolute;
                right: 0;

                .select__indicator > svg {
                    height: toRem(17);
                    width: toRem(17);
                }
            }
        }

        .select__menu {
            font-size: 0.75rem;

            .select__option {
                height: fit-content;
            }

            .select__option:hover {
                height: fit-content;
            }
        }
    }

    .dividerLine {
        height: 1.5rem;
        width: 1px;
        margin: 0 1.125rem;
        @include dashedVertical($themeMono1, 0.25rem, 75%);
    }

    .advancedFilterButton {
        background: $themeColor1;
        color: $themeMono1;
        border-radius: $borderRadius;
        font-size: 0.6875rem;
        text-transform: uppercase;
        padding: 0.1875rem 0.625rem;
        height: 1.5rem;

        svg {
            width: 1rem;
            height: 0.875rem;
            margin-right: 0.5rem;
        }

        &:hover {
            background: $themeColor12;
        }

        &.filtersOpen {
            background: $themeColor16;
        }
    }

    .searchButton {
        margin-left: 1rem;

        svg {
            margin: 0;
        }
    }
}
