@import "./src/scss/index";
.selected-person {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-n-name {
        display: flex;
        align-items: center;
        color: $themeColor24;
        cursor: unset;

        &.clickable {
            cursor: pointer;
            color: $themeColor12;
            text-decoration: underline;
        }

        svg {
            height: 2.5rem;
            width: 2.5rem;
            color: $themeColor7;
        }

        &[disabled] {
            cursor: unset;
            color: $themeMono20;

            svg {
                color: $themeMono20;
            }
        }

        .name {
            max-width: 19.5rem;
            min-width: 1rem;
            margin-left: 0.5rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &:hover &.clickable {
            color: $themeColor7;

            svg {
                color: $themeColor7;
            }
        }
    }

    .trash-icon-button {
        height: 1rem;
        width: 1rem;
        margin-left: 1.625rem;
    }
}
