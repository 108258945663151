@import "./src/scss/index";
.date-range-picker-field {
    @extend .flexRow;
    background: $themeColor4;
    min-width: toRem(128);
    height: toRem(40);
    border-radius: toRem(3);
    border: $inputBorder;
    justify-content: flex-start;
    align-items: center;
    padding-left: toRem(12);
    color: $themeMono12;
    font-size: toRem(15);
    margin-top: toRem(9);
    width: toRem(220);;
    cursor: pointer;
    &.with-time{
        min-width: toRem(280);
    }
    &:hover &:not(.disabled),
    &:focus {
        border-color: $inputBorderHover;
        outline: none;
        .arrowIcon {
            color: $themeColor7;
        }
    }

    .dateIcon {
        width: toRem(15);
        color: $themeColor7;
    }

    .dateText {
        margin-left: toRem(7);
    }

    .arrowIcon {
        margin-left: toRem(10);
        width: toRem(12);
        color: $themeColor3;
    }

    &.disabled {
        background: $themeMono2;
        border-color: $themeMono3;
        color: $themeMono10;
        outline: none;
        cursor: default;

        .dateIcon,
        .arrowIcon{
            color: $themeMono8;
        }
    }

}
