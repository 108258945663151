@import "./src/scss/index";
.person-details-panel.slidingPanel{
  width: 27.5rem;
  padding-bottom: 2.5rem;

  .content {
    display: flex;
    width: 100%;
    height: calc(100% - 11rem);

    .fields{
      display: flex;
      flex-direction: column;

      .title-value{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1.375rem;

        .title{
          font-size: 0.8125rem;
          color: #7F7F7F;
        }

        .value{
          color: black;
          font-weight: $fontWeightBold;
          white-space: nowrap;
          max-width: 22rem;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.email {
          .value {
            color: $themeColor12;

            &:hover{
              color: $themeColor7;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
