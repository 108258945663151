@import "./src/scss/index";
.inlineFields {
    @extend .flexRow;

    justify-content: space-between;
    align-items: center;

    & + .inlineFields {
        margin-top: 2rem;
    }

    .inputWrapper + .inputWrapper {
        margin-top: 0;
    }
}
