@import "./src/scss/index";
.accordion {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  position: relative;

  .accordion-item{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    .item-header{
      z-index: 2;
      display: flex;
      width: 100%;
      background-color: $themeMono2;
      padding: 0.5rem;
      align-items: center;
      cursor: pointer;

      .chevron-down{
        justify-self: flex-end;
        svg {
          width: 1.5rem;
        }
      }

      &.expanded{
        .chevron-down {
          transform: rotate(180deg);
        }
      }
    }

    .item-content{
      height: 100%;
      width: 100%;
    }
  }
}
