@import "./src/scss/index";
.default-site-selector {
    @extend .flexCol;
    position: fixed;
    background: $themeMono1;
    width: toRem(480);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $borderRadiusMedium;
    z-index: 10;

    &__header {
        @extend .flexCol;
        align-items: center;
        justify-content: center;
        padding: toRem(34) toRem(30) toRem(20) toRem(30);
        background-color: $themeColor12;
        color: $themeMono1;
        border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;

        h1 {
            font-size: toRem(28);
            line-height: toRem(28);
            font-weight: $fontWeightLight;
            margin-bottom: toRem(20);
        }

        span {
            font-size: toRem(14);
        }
    }

    &__content {
        @extend .flexCol;
        align-items: center;
        justify-content: center;
        padding: toRem(12) toRem(30) toRem(34) toRem(30);

        &--select {
            width: toRem(420);
            margin-bottom: toRem(30);
        }

        &--button {
            min-width: toRem(180);

            &:disabled {
                background: $themeMono3;
                color: $themeMono15;
            }
        }
    }
}
