@import "./src/scss/index";
.mobile-input {
    display: flex;
    height: 2.25rem;

    > input{
        width: 8.25rem!important;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin-left: -1px;
        z-index: 1;
    }

    .select-container {
        width: 5.75rem!important;
        min-width: 5.75rem!important;
        margin: 0;

        &:hover{
            z-index: 2;
        }

        .select__control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 2.25rem;
            min-height: 2.25rem;
            .select__single-value{
                text-overflow: unset;
                width: 3rem;
            }
        }

    }
    .select__menu {
        width: 14rem!important;
        text-align: start;
        margin: 0;

        .select__option {
            height: auto!important;
            min-height: 2.5rem;
        }
    }

    .select__indicator{
        padding-left: 0;
    }
}
