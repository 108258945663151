@import "./src/scss/index";
.geoStructureSelector.slidingPanel {
    overflow: hidden;
    padding: 0;

    h1 {
        margin: 3.125rem 2.5rem 0.625rem;
    }

    &.hasBreadcrumb {
        h1 {
            align-self: flex-start;
        }
    }

    .content {
        @extend.flexRow;
        width: 100%;

        .siteSelector {
            overflow: hidden;
            width: 100%;
            color: $themeMono12;
            position: relative;

            .breadcrumb {
                @extend .flexRow;
                font-size: 0.75em;
                line-height: 0.9375rem;
                color: $themeMono11;
                margin: 0 2.5rem 1.625rem;

                .backToTop {
                    @extend .flexRow;

                    svg {
                        width: 1rem;
                        height: 0.9375rem;
                        color: $themeColor13;
                        margin-right: 0.3125rem;
                    }

                    &:hover {
                        text-decoration: underline;
                        color: $themeColor13;

                        svg {
                            color: $themeColor12;
                        }
                    }
                }

                span {
                    margin: 0 0.5rem;
                }

                .currentStructure {
                    color: $themeColor12;
                }
            }

            .selectorWrap {
                width: 100%;
                height: 100%;

                ul {
                    position: absolute;
                    top: 2.5625rem;
                    left: 0;
                    right: 0;
                    overflow: auto;
                    height: calc(100% - 2.5625rem);
                    text-align: left;
                    padding: 0 2.5rem 0.75rem;

                    li {
                        @extend .flexRow;
                        align-items: center;
                        border-bottom: 1px solid $themeMono6;
                        font-size: toRem(15);

                        &:first-child {
                            border-top: 1px solid $themeMono6;
                        }

                        &:hover {
                            background: $themeColor4;
                        }

                        button {
                            @extend .flexRow;
                            position: relative;
                            align-items: center;
                            padding: 1rem 0.625rem 1rem 0.75rem;
                            line-height: toRem(15);
                            text-align: left;
                            width: 100%;

                            &.structureHasChildren {

                                &:after {
                                    content: "";
                                    position: absolute;
                                    background: url(../../assets/icons/DropdownChevron.svg) no-repeat;
                                    height: toRem(9);
                                    width: 1rem;
                                    right: 0.625rem;
                                    transform: rotate(-90deg);
                                }
                            }

                            &.selected {
                                background: $themeColor13;
                                color: $themeMono1;

                                &:after {
                                    content: "";
                                    position: absolute;
                                    background: url(../../assets/icons/siteSelector/selectedTick.svg) no-repeat;
                                    width: 1.25rem;
                                    height: 1.25rem;
                                    right: 0.625rem;
                                }
                            }

                            &.disabled {
                                color: $themeMono10;
                                cursor: default;
                            }

                            span {
                                pointer-events: none;
                                font-size: toRem(13);
                                margin-left: 0.2rem;
                            }

                            img {
                                transform: rotate(270deg);
                                height: toRem(9);
                                width: 1rem;
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
