@import "./src/scss/index";
.geographicStructureSelector {
    @extend .flexCol;
    flex: 1;
    align-items: flex-start;
    margin: 0 2.125rem;

    & > p {
        font-size: 0.75rem;
        line-height: 0.75rem;
        margin-bottom: 0.3125rem;
    }

    .currentSite {
        @extend .flexRow;

        p {
            font-size: 1.25rem;
            font-weight: $fontWeightLight;
            line-height: 1.25rem;
            position: relative;
        }

        .selectorArrow {
            background: url(../../../../assets/icons/siteSelector/selectorArrowOff.svg) no-repeat;
            width: 1.1875rem;
            height: 1.125rem;
            margin-left: 0.625rem;
            align-self: flex-end;
        }

        &:hover {
            p::after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                background: $themeMono1;
                height: 1px;
            }

            .selectorArrow {
                background-image: url(../../../../assets/icons/siteSelector/selectorArrowOn.svg);
            }
        }
    }

    .input-site-selector {
        .siteField {
            @extend .flexRow;
            background: $themeColor4;
            min-width: toRem(280);
            height: toRem(40);
            border-radius: toRem(3);
            border: $inputBorder;
            justify-content: flex-start;
            align-items: center;
            padding-left: toRem(12);
            color: $themeMono12;
            font-size: toRem(15);
            margin-top: toRem(9);
            width: 100%;
            cursor: pointer;

            &:hover,
            &:focus {
                background: $themeColor20;
                border-color: $inputBorderHover;
                outline: none;

                .arrowIcon {
                    color: $themeColor7;
                }
            }

            .arrowIcon {
                width: 0.5rem;
                transform: rotate(180deg);
                margin-left: auto;
                margin-right: 0.5rem;
            }

            &:disabled {
                background: $themeMono2;
                pointer-events: none;
                border-color: $themeMono20;

                .siteName {
                    color: $themeMono10;
                }

                .arrowIcon svg polyline {
                    stroke: $themeMono10;
                }
            }
        }
    }
}
