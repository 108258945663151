@import "./src/scss/index";
.visitor-booking-form {
    background-color: $themeMono16;
    padding-top: 2.5rem;
    padding-left: 1.875rem;
    height: 100%;
    margin-bottom: $actionButtonPanelHeight;

    .formGroup {
        width: 100%;

        & > *:not(:first-child) {
            margin-top: 1.75rem;
            margin-bottom: 1rem;
            width: 100%;
        }

        .host-search {
            margin-top: 0;
        }

        .due-time-error-message {
            margin-top: 0;
            margin-bottom: 0.5rem;
            margin-right: auto;
            height: 1.75rem;
            padding: 0 0.5rem 0 0.5rem;
            color: #ff1e1e;
            font-size: 0.875rem;
            display: inline;
            max-width: 25rem;
            width: auto;

            &.with-value {
                background-color: #ffeeee;
            }
        }

        .date-time-row {
            display: flex;
            align-items: flex-start;
            margin-bottom: 0.5rem;

            & > * {
                margin-right: 1rem;
            }

            .due-date-time {
                width: 13rem;
                min-width: 13rem;

                .dateTimePickerField {
                    min-width: 14rem;
                }
            }

            .valid-to-date {
                width: 8rem;
            }

            .due-date-time {
                margin-right: 1.5rem;
            }

            .arrow-right {
                height: 1rem;
                margin-top: 2.25rem;
                color: $themeMono17;
            }

            .multi-day-side-panel-button {
                margin-top: auto;
                height: 2.5rem;
            }

            .toggle {
                margin-top: -4px;

                label {
                    color: $themeColor13;
                    font-size: 1rem;
                    font-weight: $fontWeight;

                    &:before {
                        margin-top: 0.5rem;
                    }
                }
            }

            .multi-day-side-panel-button {
                text-transform: unset;
            }
        }

        .geographicStructureSelector {
            .siteField {
                width: 25rem;
            }
        }
    }

    .slidingPanel,
    .backgroundMask {
        margin-top: 0 !important;
    }

    .geographicStructureSelector {
        margin-left: 0;
    }
}
