@import "./src/scss/index";
.time-label-container {
    width: 100%;
    height: 100%;
    background-color: $themeColor16;
    color: $themeMono1;
    display: flex;
    justify-content: center;
    align-items: center;
}
