@import "./src/scss/index";
.series-info {
    .repeat-summary-info {
        margin-top: 1rem;
        font-size: 0.875rem;
        color: #666;
        line-height: 1rem;
    }

    .notes {
        margin-top: 1.5rem;
    }

    .inputWrapper.horizontal {
        justify-content: flex-start;

        .requiredLabel {
            position: static;
        }

        .attendees {
            margin-right: 2.5rem;
        }

        .label {
            font-size: 1rem;
        }
    }

    .toggle {
        label {
            color: $themeColor13;
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .edit-repeat-button {
        margin-top: 1rem;
        padding: 0.1rem 0.75rem;
        svg {
            margin-right: 0.625rem;
            fill: $themeMono1;
        }
    }
}
