@import "./src/scss/index";
.option {
  @extend .flexRow;
  align-items: center;
  height: 3.125rem;
  border-bottom: 1px solid $themeMono6;
  padding-left: 0.875rem;

  .optionName {
    font-size: 0.875rem;
    color: $themeMono11;
    margin-left: 0.625rem;

    &.checked {
      font-weight: bold;
    }
  }

  input[type='checkbox'] {
    position: relative;
    width: 0.75rem;
    height: 0.75rem;
    appearance: none;
    outline: 1px solid $themeMono6;
    outline-offset: 0.125rem;
    border-radius: 0.125rem;
    cursor: pointer;

    &:hover {
      outline-color: $themeColor12;
    }

    &:checked {
      outline-color: $themeColor12;
      background-color: $themeColor12;
    }
  }
}
