@import "./src/scss/index";
.date-range-picker {
    .slidingPanel{
        width: 50rem;
    }

    .range-calendars{
        display: flex;
        justify-content: space-between;
        width: 45rem;

        .title-n-calendar {
            width: 20rem;

            .date-label {
                font-weight: $fontWeightLight;
                font-size: toRem(20);
                margin-bottom: 1rem;
            }
        }

        .rdtPicker{
            padding: 1.5rem 0;
            border-top: 1px solid $themeMono6;
            border-bottom: 1px solid $themeMono6;

        }
    }
}
