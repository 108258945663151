@import "./src/scss/index";
.viewsPanel {
    @extend .flexCol;
    margin-left: 2.5rem;

    .panelTitle {
        margin-bottom: 0.5rem;
        color: $themeMono1;
        text-transform: uppercase;
        font-size: 0.625rem;
        line-height: 0.625rem;
    }

    .panelButtons {
        @extend .flexRow;
    }
}
