@import "./src/scss/index";
.button {
    @extend .flexRow;
    @include noSelect;
    align-items: center;
    justify-content: center;
    border: solid 1px transparent;
    border-radius: $borderRadius;
    font-size: toRem(15);
    font-weight: $fontWeight;
    padding: 0.35rem 2rem;
    text-transform: uppercase;
    background-color: $themeColor2;
    color: $themeMono1;
    transition: background-color 0.25s linear;
    &:not(:disabled) {
        &:hover {
            background-color: darken($themeColor2, 10%);
            cursor: pointer;
        }
    }

    &.primary {
        background-color: $themeColor5;
        color: $themeMono12;
        &:not(:disabled) {
            &:hover {
                background-color: $themeColor19;
            }
        }
    }

    &.secondary {
        background-color: $themeMono11;
        &:not(:disabled) {
            &:hover {
                background-color: $themeMono12;
            }
        }
    }

    &.danger {
        background-color: $themeColor6;
        &:not(:disabled) {
            &:hover {
                background-color: $themeColor18;
            }
        }
    }

    &.success {
        background-color: $themeColor31;
        &:not(:disabled) {
            &:hover {
                background-color: $themeColor32;
            }
        }
    }

    &.edit {
        background-color: $themeColor12;
        &:not(:disabled) {
            &:hover {
                background-color: $themeColor33;
            }
        }
    }

    &.transparent {
        background-color: transparent;
        border-color: $themeColor2;
        color: $themeColor2;

        &:not(:disabled) {
            &:hover {
                background-color: $themeMono4;
            }
        }
    }

    &:disabled {
        cursor: default;
        background-color: $themeMono7;
    }

    &:active,
    &:focus {
        outline: 0;
    }
    &:focus-visible {
        outline: initial;
    }

    &.iconOnly {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        span {
            display: none;
        }

        &:hover {
            background: transparent;
            color: $themeColor3 !important;
        }
    }

    svg {
        height: 2rem;
    }

    // Covers the "submitting" spinner (three dots)
    > div {
        margin-left: 0.5rem;

        svg {
            width: 2rem;
            height: 1rem;
        }
    }

    &.primary {
        > div {
            svg {
                fill: $themeMono11;
            }
        }
    }

    &.secondary {
        > div {
            svg {
                fill: $themeMono1;
            }
        }
    }
}
