@import "./src/scss/index";
.resourceInfoContent {
    .resourceArea {
        display: flex;
        justify-content: space-between;
        margin-bottom: toRem(12);

        &-container {
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
                width: 19px;
                height: 17px;
                color: $themeMono13;
            }

            .attendeesCount {
                font-size: 1rem;
                font-weight: bold;
            }

            .capacity {
                font-size: 0.875rem;
                font-weight: lighter;
            }
        }
    }

    .resourceTimeLine {
        margin-bottom: toRem(12);
    }
}

.resourceInfoContentSlider {
    padding: 0 30px;

    .resourceImage {
        margin-bottom: toRem(12);
    }
}
