@import "./src/scss/index";
.slidingPanel {
    @extend .flexCol;
    align-items: center;

    background-color: $themeMono1;
    height: 100vh;
    position: fixed;
    padding: 3.125rem 2rem;
    right: 0;
    top: 0;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    width: 25.625rem;
    will-change: transform;
    z-index: 7;
    overflow-x: hidden;

    .title-icon {
        height: 5rem;
        width: 5rem;
        margin-bottom: 1.125rem;
    }

    .content {
        @extend .flexCol;
        flex: 1 0 auto;
        justify-content: space-between;
        max-height: calc(100% - 3rem);
        width: 100%;
    }

    &.open {
        transform: translateX(0);
    }

    button.close,
    button.angle-button {
        position: absolute;
        top: 0;
        left: 0;
        color: $headerCopyColor;
        padding: 0.9375rem;
        border: none;

        svg {
            height: 1.125rem;
            width: 1.125rem;
        }
    }

    .completeAction {
        margin: 3.125rem auto 0;
    }

    h1 {
        color: $headerCopyColor;
        font-size: 1.5rem;
        font-weight: $fontWeightLight;
        margin-bottom: 2.75rem;
        line-height: 1;
    }

    h3 {
        color: $headerCopyColor;
        font-size: 1.1rem;
        font-weight: $fontWeightLight;
        line-height: 1;
    }

    .subTitle-mark {
        color: $headerCopyColor;
        font-size: 1.1rem;
        font-weight: $fontWeight;
        line-height: 1;
    }
}
