@import "./src/scss/index";
.react-horizontal-scrolling-menu--wrapper {
    flex-direction: row;
    overflow-x: auto;
    width: 100%;

    .react-horizontal-scrolling-menu--header,
    .react-horizontal-scrolling-menu--footer {
        display: none;
    }

    .react-horizontal-scrolling-menu--inner-wrapper {
        width: 100%;
        height: 100%;
    }

    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
    }

    .react-horizontal-scrolling-menu--scroll-container {
        background-color: $themeMono11;
        height: 100%;
        width: 100%;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .react-horizontal-scrolling-menu--item {
            display: flex;
            justify-content: center;
        }
    }

    .react-horizontal-scrolling-menu--arrow-left,
    .react-horizontal-scrolling-menu--arrow-right {
        width: toRem(24px);
        align-items: center;
        justify-content: center;
        background-color: $themeColor2;
        cursor: pointer;
        z-index: 1;

        .arrow-selector {
            background: url(../../../../../../assets/icons/siteSelector/selectorArrowOn.svg) no-repeat;
            width: 1.1875rem;
            height: 1.125rem;

            &.left {
                transform: rotate(180deg);
            }
        }
    }
}
