@import "./src/scss/index";
.reception-page {
  height: calc(100vh - 4.625rem); // TODO investigate and set global for layout

  .tabsContainer {
    .tabContent{
      padding: 0;
    }

    .tabs {
      height: 90px;
      padding-left: 0rem !important;

      .month {
        color: #ffffff;
      }

      .tab {
        align-self: flex-end;
      }

      .booking__datePicker {
        padding-left: 1.5rem;
      }
    }
  }
}
