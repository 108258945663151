@import "./src/scss/index";
.data-table {
    height: 100%;

    .data-grid-component {
        border: none;
        background-color: $themeMono16;

        .MuiDataGrid-columnHeaders {
            .MuiDataGrid-columnHeader {
                outline: none !important;
                font-size: 16px;
                color: black;

                .MuiDataGrid-columnSeparator {
                    visibility: hidden;
                }

                .MuiDataGrid-columnHeaderTitleContainer {
                    .MuiButtonBase-root {
                        visibility: visible;

                        &:hover {
                            background-color: unset;
                        }

                        color: $themeMono6;
                    }
                }

                &.MuiDataGrid-columnHeader--sorted {
                    .MuiDataGrid-columnHeaderTitleContainer {
                        .MuiButtonBase-root {
                            color: $themeColor12;
                        }
                    }
                    color: $themeColor12;
                }
            }
        }

        .MuiDataGrid-virtualScroller {
            .MuiDataGrid-virtualScrollerContent {
                padding-top: 1rem;

                .MuiDataGrid-row {
                    max-height: 6rem !important;

                    &:hover {
                        background-color: unset;
                    }

                    &:nth-child(2n-1) {
                        background-color: $themeMono2;
                    }

                    .MuiDataGrid-cell {
                        border: none;
                        border-right: 2px solid white;
                        overflow-x: auto;
                        max-height: 6rem !important;

                        .table-cell-truncate {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        &.multiline {
                            box-sizing: border-box;
                            white-space: initial;
                            align-items: center;
                            padding-right: 0;
                            word-wrap: break-word;

                            &.custom {
                                white-space: pre;
                            }

                            div {
                                max-height: 6rem;
                                overflow-y: auto;
                                overflow-x: hidden;
                                width: 100%;
                                padding: 0.75rem 0.5rem 0.75rem 0;
                            }
                        }

                        &:not(.multiline) {
                            -ms-overflow-style: none; /* Internet Explorer 10+ */
                            scrollbar-width: none; /* Firefox */

                            &::-webkit-scrollbar {
                                display: none; /* Safari and Chrome */
                            }
                        }

                        &:focus {
                            outline: none;
                        }

                        &.capitalize {
                            text-transform: capitalize;
                        }
                    }
                }

                .pagination {
                    padding: 1rem;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}
