@import "./src/scss/index";
.fixedResources,
.restrictions {
    @extend .flexRow;
    padding: 1.375rem 0;
    border-bottom: 1px solid $themeMono6;
    width: 100%;

    img {
        width: 1.25rem;
        height: 1.25rem;
    }

    .content {
        @extend .flexCol;
        margin-left: toRem(10);
        width: 100%;
        overflow-wrap: break-word;

        .title {
            font-size: $fontSizeLabel;
            line-height: toRem(14);
            margin-bottom: toRem(12);
            font-weight: $fontWeight;
        }

        & > .restrictions-list {
            list-style: none;
            column-count: 3;

            li {
                font-size: toRem(12);
                color: $themeMono11;
                line-height: 1.19rem;

                &::before {
                    content: '';
                    height: toRem(5);
                    width: toRem(5);
                    display: inline-block;
                    background-image: url('../../assets/icons/Bullet.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-right: toRem(5);
                    vertical-align: middle;
                }
            }
        }

        & > .slider-view {
            column-count: 1;
        }
    }
}
