@import "./src/scss/index";
.floorplan-header {
    display: flex;
    height: 2.5rem;
    border-top: 1px solid $themeMono1;
    border-bottom: 1px solid $themeMono1;
    box-shadow: $dialogShadow;

    .content {
        width: inherit;

        .slider-container {
            margin: 0 1rem;

            .radio-button-selector {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                height: auto;

                .MuiFormGroup-row {
                    flex-direction: column;
                }

                .MuiSelect-select {
                    display: flex;
                }

                .MuiFormControlLabel-label {
                    font-family: $fontFamily;
                    font-size: 1rem !important;
                    color: $themeMono12;
                    font-weight: $fontWeight;
                }

                .MuiButtonBase-root .unchecked-item svg,
                .MuiButtonBase-root .checked-item svg {
                    width: toRem(20);
                    height: toRem(20);
                }

                .MuiButtonBase-root:hover svg circle {
                    stroke: $positive;
                }
            }

            .validation-container {
                display: flex;
                flex-direction: column;
                margin-left: 1.5rem;

                .time-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-bottom: 1rem;

                    .inputWrapper {
                        margin: 0;
                    }

                    .MuiInputBase-root {
                        font-family: inherit;
                    }
                }

                .validation-message {
                    color: $themeColor21;
                    font-size: toRem(14);
                }
            }
        }
    }

    .slider-container::before,
    .slider-container::after {
        content: '';
        width: 80%;
        border-top: 1px solid $themeMono9;
        align-self: center;
        margin: 0.5rem 0;
    }
}
