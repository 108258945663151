@import "./src/scss/index";
.select-container {
    width: 100%;
    margin: toRem(9) 0 0 0;
    position: relative;
    &--has-value {
        .select__control {
            background: $themeColor4 !important;
        }
    }

    .select__control {
        border-radius: $borderRadius;
        color: $themeMono12;
        font-size: toRem(15);
        border: $inputBorder;
        background: $themeMono1;

        &:hover,
        &--is-focused {
            background: $themeColor20 !important;
            border-color: $inputBorderHover !important;
            box-shadow: none;
            .select__indicators {
                .select__dropdown-indicator {
                    color: $themeColor1;
                }
            }
        }
        &--is-disabled {
            background: $inputBackgroundDisabled !important;
            border-color: $inputBorderDisabled;
            color: $inputColorDisabled !important;
            .select__placeholder,
            .select__indicators {
                display: none;
            }
        }

        .select__value-container {
            border-radius: $borderRadius;
            padding: 0 0.75rem;

            > div {
                padding: 0;
                margin: -4px;
            }
        }

        .select__indicators {
            border-radius: $borderRadius;
            background: transparent;
            height: 100%;
            .select__indicator-separator {
                display: none;
            }

            .select__dropdown-indicator {
                color: $themeColor2;
            }
        }
    }

    .select__menu {
        color: $themeMono12;
        font-size: toRem(14);
        font-weight: $fontWeightLight;

        .select__option {
            min-height: toRem(40);
            &--is-selected {
                background-color: $themeColor4;
                font-weight: $fontWeightBold;
                color: $themeMono12;
            }
            &--is-focused {
                background-color: $themeColor13;
                font-weight: $fontWeight;
                color: $themeMono1;
            }
        }
    }
}
