@import "./src/scss/index";
// Displays in 3 columns by default
.formGroup {
    @extend .flexCol;
    width: 28%;
    margin-right: 6%;
    border: none;

    &:last-child {
        margin-right: 0;
    }

    input[type='text'],
    select,
    .dateTimePicker {
        width: 100%;
    }
}
