@import "./src/scss/index";
.selected-sites-panel{
  width: 25rem;
  height: 100%;
  background-color: $themeMono1;
  box-shadow: 0 1px 4px 1px $themeMono8;

  .panel-title {
    margin: 1.75rem 1rem 0 1rem;
    height: 1.5rem;
  }
  .panel-content {
    height: calc(100% - 3.5rem);

    .site-accordion-header-item {
      display: flex;
      font-size: 10px;
      width: 100%;
      height: 3rem;
      align-items: center;
      color: $themeMono12;

      .site-name, .site-group-name{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 16rem;
      }

      .site-group-name {
        font-size: 0.625rem;
      }

      .site-name {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;
      }

      .site-icon {
        width: 2.25rem;
        height: 2.25rem;
        background-color: $themeColor2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 1.15rem;
          color: $themeMono1;
        }
      }

      .site-info {
        display: flex;
        padding: 0 0.5rem;
        flex-direction: column;

        .time-block {
          display: flex;

          .time-icon {
            color: $themeColor2;
            margin-right: 0.25rem;

            svg {
              width: 0.875rem;
            }
          }

          .time {
            margin-right: 0.25rem;
          }
        }
      }
    }
    .no-resource-selected-placeholder{
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
      color: $themeMono10;
      border-bottom: 1px solid $themeMono10;
    }

    .resource-accordion-content-item{
      padding: 0.5rem 0;

      display: flex;
      color: $themeMono12;
      align-items: center;
      border-bottom: 1px solid $themeMono10;

      .resource-image {
        width: 4.25rem;

        &:hover {
          cursor: pointer;
        }

        .info-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          color: $themeMono1;
          background-color: $themeColor29;
          border-bottom: 1px solid;
          border-right: 1px solid;
          height: 1.25rem;
          width: 1.25rem;
          align-self: flex-start;
        }
      }

      .trash-icon{
        color: $themeMono10;
        height: 1.125rem;
        margin-left: auto;
        margin-right: 0.25rem;
      }

      .resource-info {
        margin-left: 0.5rem;

        .area-name{
          font-size: 0.75rem;
          line-height: 0.75rem;
        }

        .resource-info-icons {
          display: flex;
          margin-top: 0.25rem;

          svg {
            height: 1.125rem;
            margin-right: 0.25rem;
          }

          .room-capacity{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.875rem;
            min-width: 2.5rem;
            height: 1.125rem;
            color: $themeMono1;
            background: $themeColor29;
            border-radius: 10px;
            margin-right: 0.25rem;
            padding: 0 0.5rem;

            svg {
              height: 0.75rem;
              margin-right: 0.125rem;
            }
          }
        }
      }

    }
  }
}
