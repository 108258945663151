@import "./src/scss/index";
$width: 16.125rem;

.rdt {
    width: 100%;
    min-width: $width;

    table {
        width: 100%;
        line-height: 1;
    }

    thead {
        tr:first-child {
            height: 3.25rem;
            vertical-align: text-top;

            .rdtNext,
            .rdtPrev {
                color: $themeColor3;
                font-size: 2rem;
                line-height: 0;

                span {
                    vertical-align: -2px;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .rdtNext {
                text-align: left;
            }

            .rdtPrev {
                text-align: right;
            }

            .rdtSwitch {
                &:hover {
                    cursor: pointer;
                }

                font-size: 1.125rem;
            }
        }

        tr:nth-child(2) {
            height: 2.625rem;
            vertical-align: text-top;
        }
    }

    .dow,
    td {
        width: 2.9464rem;
        min-width: 2.9464rem;
        max-width: 2.9464rem;
    }

    td {
        height: 2.4375rem;
    }

    .rdtToday {
        color: $todayColor;
    }

    .rdtDay:hover:before {
        background-color: $dayHoverColor;
    }

    .rdtActive:before {
        background-color: $dayActiveColor;
    }

    .rdtSwitch,
    .dow {
        font-weight: $fontWeightBold;
    }

    .dow {
        font-size: 1rem;
    }

    .rdtSwitch,
    .dow,
    .rdtDay {
        color: $themeMono11;
    }

    .rdtDay {
        cursor: pointer;
        font-size: 0.9375rem;
        position: relative;
    }

    .rdtOld,
    .rdtNew,
    .rdtDisabled {
        color: $themeMono9;
    }

    .rdtDay:hover,
    .rdtActive {
        &:not(.rdtDisabled) {
            color: $themeMono1;
            &:before {
                content: '';
                border: solid 1px $themeColor3;
                border-radius: 50%;
                display: block;
                height: 2.0625rem;
                width: 2.0625rem;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
        }
    }

    .rdtDisabled {
        cursor: default;
    }

    .rdtYear,
    .rdtMonth {
        &:hover {
            cursor: pointer;
            border-color: $timePickerBorderHover;
            background-color: $timePickerBackgroundHover;
        }

        &.rdtActive {
            color: $themeColor7;

            &:before {
                display: none;
            }
        }
    }

    .rdtMonth.rdtActive:before {
        content: '';
        display: none;
    }
}

.rdt + .inlineFields {
    margin-top: 1rem;
}

.slidingPanel {
    .dateEditor {
        input.form-control {
            display: none;
        }
    }
    .editor {
        @extend .flexCol;
        flex: 1 0 auto;
        align-items: center;
        width: 100%;

        small {
            display: flex;
            color: $themeColor6;
            margin-top: 1.25rem;
            text-align: left;
            align-items: center;
            font-size: 0.75rem;
            margin-bottom: 1.5rem;

            svg {
                height: 2rem;
                width: 2rem;
                margin-right: 0.5rem;
                flex-shrink: 0;
            }
        }

        .react-select-container {
            height: 2.75rem;
            width: 6.625rem;
            align-self: center;

            &.error .react-select__control {
                border-color: $themeColor6;
            }

            .react-select__control {
                height: 100%;
                border: $inputBorder;
                border-radius: 1.8rem;

                &--is-focused {
                    outline: 0;
                }

                &:hover {
                    border-color: $timePickerBorderHover;
                    background-color: $timePickerBackgroundHover;
                }

                .react-select__value-container {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;

                    .react-select__single-value {
                        color: $themeMono11;
                        font-size: 1.25rem;
                        font-weight: $fontWeightLight;
                        line-height: 1.25rem;
                    }

                    & div:nth-child(2) {
                        position: absolute;
                        top: -0.5rem;
                    }
                }
            }

            .react-select__indicator,
            .react-select__indicator-separator {
                display: none;
            }

            .react-select__menu {
                width: 7rem;
                top: 0;
                transform: translateY(-50%);
            }

            .react-select__option {
                color: $themeMono12;
                font-weight: lighter;

                &--is-selected {
                    background-color: $themeColor4;
                    font-weight: normal;
                }

                &--is-focused {
                    background-color: $themeColor1;
                    color: $themeMono1;
                }
            }
        }
    }

    .inlineFields {
        width: $width;

        .inputWrapper {
            flex: 1;
            label {
                font-size: 0.875rem;
                font-weight: $fontWeightBold;
                width: 100%;
                margin-bottom: 0.625rem;
                color: $themeMono11;
            }
        }

        svg.separator {
            width: 0.75rem;
            color: $themeMono11;
            margin: 1.5rem 0.9375rem 0;
        }

        .toggleLabel {
            color: $themeMono11;
            font-size: 0.8rem;
            font-weight: $fontWeightBold;
            align-self: flex-end;
            margin-bottom: 0.4rem;
        }
    }
}

.field {
    cursor: pointer;

    &:disabled {
        cursor: default;
    }
}
