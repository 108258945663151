@import "./src/scss/index";
.time-slot-card {
    color: $themeMono1;
    font-size: toRem(12);
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $themeMono1;
    min-width: toRem(120);
    position: relative;
    cursor: pointer;

    &.selected {
        background-color: $themeColor16;
    }
}
