@import "./src/scss/index";
.search-results-container {
    margin-top: 1rem;

    .result {
        color: $themeMono11;
        font-size: 0.875rem;
        margin-bottom: 0.3rem;
    }

    .count {
        color: $themeColor24;
        font-weight: 700;
    }

    .data {
        border-top: 1px solid $themeMono6;
        border-bottom: 1px solid $themeMono6;
    }

    .no-data-text {
        text-align: center;
        font-size: 1rem;
        color: $themeMono11;
        font-weight: 300;
        padding: 4rem 0;
    }

    .no-results {
        font-weight: bold;
    }
}
