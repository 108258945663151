@import "./src/scss/index";
.data-table {
    .data-grid-component {
        background-color: $themeMono1;
    }

    .MuiDataGrid-row {
        &:last-child {
            border-bottom: 1px solid $themeMono6;
        }
    }
}
